import React from 'react';
import './SignUp.scss';
import SignUpForm from '../../components/SignUp/SignUpForm';

const SignUp: React.FC = () => {

  return (
      <SignUpForm />
  );
};

export default SignUp;
