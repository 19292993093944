import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import API from '../../api/index';
import { IonSpinner } from '@ionic/react';
import { AuthResponse } from '../../models/api/AuthResponse';
import { IonLabel } from '@ionic/react';
import './ForgotPassword.scss';
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

type ForgotPwdForm = {
  closeHandler: () => void;
};

type ForgotPasswordRequest = {
  username: string;
  email: string;
  dob: Moment;
};

const ForgotPwdForm: React.FC<ForgotPwdForm> = (props) => {
  const { closeHandler } = props;
  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [successMsg, setSuccessMsg] = useState<string>(null);
  const [dob, setDob] = useState<Moment>(moment());

  const formik = useFormik({
    initialValues: { username: '', email: '', dob: null },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: (values) => {
      setErrorMsg(null);
      setSuccessMsg(null);
      const data: ForgotPasswordRequest = {
        username: values.username,
        email: values.email,
        dob: dob.startOf('day'),
      };
      forgotPassword(data);
    },
  });

  const forgotPassword = async (data: ForgotPasswordRequest) => {
    try {
      setProgress(true);
      const response: AuthResponse = await API.put(
        '/user/forgot-user-password',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setProgress(false);
        setSuccessMsg(response.data.message);
        setTimeout(() => closeHandler(), 5000);
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrorMsg(err.response.data.message);
      }
      setProgress(false);
    }
  };

  useEffect(() => {
    setErrorMsg(null);
    setSuccessMsg(null);
  }, []);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="forgot-pwd-form-ctn"
      autoComplete="off"
    >
      <div className="usr-input">
        <IonLabel className="input-label">Username</IonLabel>
        <TextField
          className="login-input-field user-name"
          type="text"
          name="username"
          variant="outlined"
          error={
            formik.touched.username && formik.errors.username ? true : false
          }
          helperText={
            formik.touched.username && formik.errors.username
              ? formik.errors.username
              : null
          }
          {...formik.getFieldProps('username')}
        />
      </div>

      <div className="usr-input">
        <IonLabel className="input-label">Email Id</IonLabel>
        <TextField
          className="login-input-field user-name"
          type="text"
          name="email"
          variant="outlined"
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
          {...formik.getFieldProps('email')}
        />
      </div>

      <div className="usr-input">
        <IonLabel className="input-label">Date Of Birth</IonLabel>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className="login-input-field user-name"
            disableToolbar
            variant="inline"
            format="DD/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={dob ? dob : null}
            onChange={(e) => {
              if (e == null) {
                setDob(null);
              } else {
                setDob(e);
              }
            }}
            InputProps={{
              disableUnderline: true,
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      {errorMsg !== '' ? <span className="error-msg">{errorMsg}</span> : null}
      {successMsg !== '' ? (
        <span className="success-msg">{successMsg}</span>
      ) : null}
      <Button
        className="confirm-btn"
        color="primary"
        endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
        type="submit"
        variant="contained"
      >
        Confirm
      </Button>
    </form>
  );
};

export default ForgotPwdForm;
