import { Sport } from '../models/Sport';

export const SPORTS_MAP: SportsMap = {
  'sr:sport:1': {
    id: 'sr:sport:1',
    name: 'Soccer',
    slug: 'soccer',
    tournaments: [],
    priority: 90,
  },
  'sr:sport:2': {
    id: 'sr:sport:2',
    name: 'Basketball',
    slug: 'basketball',
    tournaments: [],
    priority: 70,
  },
  'sr:sport:3': {
    id: 'sr:sport:3',
    name: 'Baseball',
    slug: 'baseball',
    tournaments: [],
    priority: 60,
  },
  'sr:sport:4': {
    id: 'sr:sport:4',
    name: 'Ice Hockey',
    slug: 'ice-hockey',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:6': {
    id: 'sr:sport:6',
    name: 'Handball',
    slug: 'handball',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:12': {
    id: 'sr:sport:12',
    name: 'Rugby',
    slug: 'rugby',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:20': {
    id: 'sr:sport:20',
    name: 'Table Tennis',
    slug: 'table-tennis',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:21': {
    id: 'sr:sport:21',
    name: 'Cricket',
    slug: 'cricket',
    tournaments: [],
    priority: 100,
  },
  'sr:sport:22': {
    id: 'sr:sport:22',
    name: 'Darts',
    slug: 'darts',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:23': {
    id: 'sr:sport:23',
    name: 'Volleyball',
    slug: 'volleyball',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:29': {
    id: 'sr:sport:29',
    name: 'Futsal',
    slug: 'futsal',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:31': {
    id: 'sr:sport:31',
    name: 'Badminton',
    slug: 'badminton',
    tournaments: [],
    priority: 0,
  },
  'sr:sport:34': {
    id: 'sr:sport:34',
    name: 'Beach Volley',
    slug: 'beach-volley',
    tournaments: [],
    priority: 0,
  },
};

export interface SportsMap {
  [sportId: string]: Sport;
}
