import API from '../../api';
import { CasinoGameDTO, casinoProps } from '../../models/CasinoState';
import {
  UPDATE_CASINO_GAMES,
  UPDATE_IND_CASINO_GAMES,
} from './CasinoActionTypes';

export const updateIndCasinoGames = (indCasinoGames: CasinoGameDTO[]) => {
  return {
    type: UPDATE_IND_CASINO_GAMES,
    payload: indCasinoGames,
  };
};

export const updateCasinoGames = (casinoGames: {[key:string]: casinoProps[]}) => {
  return {
    type: UPDATE_CASINO_GAMES,
    payload: casinoGames,
  };
};
