import React from 'react';
import { IonButton } from '@ionic/react';

import './MailVerificationAlert.scss';
import API from '../../api';

type AlertProps = {
  message: string;
};

const MailVerificationAlert: React.FC<AlertProps> = (props) => {
  const { message } = props;

  const sendMailHandler = async () => {
    try {
      const response = await API.post('/mail/verification-token', null, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
        },
      });
      if (response.status === 200) {
        alert('Please check your email for activation link');
      }
    } catch (ex) {
      alert('Error while sending email');
    }
  };

  return (
    <div className="mva-ctn">
      <div className="msg">{message}</div>
      <IonButton color="primary" onClick={sendMailHandler}>
        Send Verification Email
      </IonButton>
    </div>
  );
};

export default MailVerificationAlert;
