export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: '7', name: 'Horserace', slug: 'horseracing' },
  { id: '4339', name: 'Greyhound', slug: 'greyhoundracing' },
  { id: '7511', name: 'Baseball', slug: 'baseball' },
  { id: '7522', name: 'Basketball', slug: 'basketball' },
];

export const EXCH_SPORTS_MAP = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  horseracing: '7',
  greyhoundracing: '4339',
  baseball: '7511',
  basketball: '7522',
};
