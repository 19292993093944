import JSEncrypt from 'jsencrypt';
import moment from 'moment';
import { BRAND_DOMAIN, BRAND_NAME } from '../constants/Branding';
import { RsaPublicKey } from '../constants/RSAPublicKey';

export function similarity(s1: string, s2: string) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / longerLength;
}

function editDistance(s1: string, s2: string) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs: number[] = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

export function convertUTCToIST(date: string) {
  let dt = moment(date);
  dt.local();
  return moment(dt).format('DD-MM-YY, h:mm A');
}
export function getSportNameByIdMap(eventType: string) {
  return SportNameByIdMap[eventType];
}
export const SportNameByIdMap: { [key: string]: string } = {
  '1': 'soccer',
  '2': 'tennis',
  '4': 'cricket',
  '7522': 'basketball',
  '7511': 'baseball',
  '4339': 'greyhoundracing',
  '7': 'horseracing',
};

export const TransactionTypeMap: { [key: string]: string } = {
  '0': 'Deposit',
  '1': 'Withdraw',
  '2': 'Settlement Deposit',
  '3': 'Settlement Withdraw',
  '4': 'Bet Placement',
  '5': 'Bet Settlement',
  '6': 'Rollback Bet Settlement',
  '7': 'Void Bet Settlement',
  '8': 'Casino Bet Placement',
  '9': 'Casino Bet Settlement',
  '10': 'Casino Bet Rollback',
  '11': 'Win Commission',
  '12': 'Win Commission Reversal',
  '13': 'Indian Casino Bet Placement',
  '14': 'Indian Casino Bet Settlement',
  '15': 'Commission Claim',
  '16': 'Commission Deposit',
  '17': 'Commission Rollback',
  '18': 'Commission Void',
  '19': 'Casino Bet Placement',
  '20': 'Casino Bet Settlement',
  '21': 'Casino Rollback',
  '22': '-',
  '23': 'Binary Bet Placement',
  '24': 'Binary Bet Settlement',
  '25': 'Binary Bet Rollback',
  '26': 'Bet Placement',
  '27': 'Bet Settlement',
  '28': 'Bet Rollback',
  '29': 'Bet Void',
  '30': 'Bet Cancelled',
  '31': 'Bet Expired',
  '32': 'Bet Lapsed',
  '33': 'Bet Deleted',
  '34': 'Risk Update',
  '35': 'Binary Bet Void',
  '36': 'Sport Book Bet Placement',
  '37': 'Sport Book Bet Settlement',
  '38': 'sport Book Bet Rollback',
  '39': 'Rolling Commission',
  '40': 'Rolling Commission Rollback',
  '41': 'Win Commission Update',
  '42': 'Void Rollback',
  '43': 'Incentive Transfer',
  '44': 'Claim Incentive',
  '45': 'Bonus Redeemed',
  '46': 'Bonus Rollback',
  '47': 'Refund',
};

export const IndianCasinoMarketNameMap: { [key: string]: string } = {
  '0': 'Lucky 7B',
  '1': 'Lucky 7A',
  '2': 'Teenpatti',
  '3': 'Dragon Tiger 2020',
  '4': 'Dragon Tiger Lion',
  '5': 'Bollywood Casino AAA',
  '6': 'Race 2020',
  '7': '32 Card',
  '8': '3Card Judgement',
  '9': 'Andar Bahar',
};

export const OutcomeDescMap = {
  '0': 'Lost',
  '1': 'Win',
  '2': 'Unsettled',
  '11': 'Unsettled',
  '3': 'Rolled Back',
  '4': 'Void',
};

export const MarketIdNameMap: { [key: string]: string } = {
  '0': 'Match odds',
  '1': 'Book maker',
  '2': 'Fancy',
  '3': 'Premium Market',
  '4': 'Casino',
  '5': 'Binary',
  '6': 'Indian Casino',
};

export const AlertBetStatusMap: { [key: string]: string } = {
  '0': 'UnAlerted',
  '1': 'Accepted',
  '2': 'Alerted',
  '3': 'Voided',
};
export const MarketIdNameByEnumMap: { [key: string]: string } = {
  MATCH_ODDS: 'Match odds',
  BOOKMAKER: 'Bookmaker',
  FANCY: 'Fancy',
  PREMIUM: 'Premium',
  CASINO: 'Casino',
  BINARY: 'Binary',
  INDIAN_CASINO: 'Indian Casino',
};

export const HierarchyOrderMap: { [key: string]: string } = {
  HA: 'HA',
  SDM: 'SSS',
  DM: 'SS',
  SM: 'SM',
  A: 'M',
  U: 'U',
};

export const DirectionMap: { [key: string]: string } = {
  UP_LINE: 'Up Line',
  DOWN_LINE: 'Down Line',
};

export const SportNameByID: { [key: string]: string } = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  horseracing: '7',
  greyhoundracing: '4339',
  baseball: '7511',
  basketball: '7522',
};

export const SportByID: { [key: string]: string } = {
  '1': 'Soccer',
  '2': 'Tennis',
  '4': 'Cricket',
  '7': 'Horse Racing',
  '4339': 'GreyHound Racing',
  '7511': 'Baseball',
  '7522': 'Basketball',
};

export function getTransactionNameByID(trans: string) {
  return TransactionTypeMap[trans];
}

export function getSportByID(id: string) {
  return SportByID[id];
}

export function getOutcomeDescName(eventType: string) {
  return OutcomeDescMap[eventType];
}

export function NumFormater(value: string | number, decimal?: number) {
  // return Number(value).toLocaleString(navigator.language);

  return value
    ? Number(value)
        .toFixed(decimal ? decimal : 2)
        .replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
    : 0.0;
}

export function MarketTypeByID(id: string) {
  return MarketIdNameMap[id] ? MarketIdNameMap[id] : '-';
}

export const OfflineTransactionTypeMap: { [key: string]: number } = {
  BANK_TRANSACTION: 0,
  UPI_PHONE_PE: 1,
  UPI_GPAY: 2,
  PAYTM: 3,
};

export const OfflineTransactionStatusTypeMap: { [key: string]: number } = {
  IN_PROGRESS: 0,
  ACCEPTED: 1,
  DECLINED: 2,
};

export const getEncreptedPwd = (password) => {
  let RSAEncrypt = new JSEncrypt({ default_key_size: '1024' });
  RSAEncrypt.setPublicKey(RsaPublicKey);
  return RSAEncrypt.encrypt(password);
};

export const RoleNameMap = {
  'House-Admin': 'House Admin',
  'Super-Duper-Master': 'SDM',
  'Duper-Master': 'DM',
  'Super-Master': 'SM',
  Agent: 'Agent',
  User: 'User',
};
export const ApprovalTypeMap: { [key: string]: string } = {
  WITHDRAW: 'Withdraw',
  DEPOSIT: 'Deposit',
};

export const UserStatusMap: { [key: number]: string } = {
  0: 'Suspended',
  1: 'Active',
  2: 'Accept Terms and Reset Password',
  3: 'Bet Locked',
  4: 'Reset Password',
};

export const OrderCategoryMap: { [key: string]: string } = {
  '0': 'Sports',

  '1': 'Premium',

  '2': 'Sports Book',

  '3': 'Casino',

  '4': 'Indian Casino',

  '5': 'Binary',

  '6': 'Sap Exchange',

  soccer: 'Soccer',

  tennis: 'Tennis',

  cricket: 'Cricket',

  horseracing: 'Horse Racing',

  greyhoundracing: 'GreyHound',

  baseball: 'BaseBall',

  basketball: 'BasketBall',
};

export function MarketTypeByEnum(id: string) {
  return MarketIdNameByEnumMap[id] ? MarketIdNameByEnumMap[id] : '-';
}
export const oddValueFormatter = (marketType, oddValue, sessionRuns) => {
  let res = '-';
  switch (marketType) {
    case 'BOOKMAKER':
    case 1:
      res = Number(oddValue * 100 - 100).toFixed(2);
      break;
    case 'FANCY':
    case 2: // fancy
      res = Number(sessionRuns).toFixed(0);
      break;
    case 'BINARY':
    case 5: // binary
      res = Number(sessionRuns).toFixed(0);
      break;
    default:
      res = Number(oddValue).toFixed(2);
  }

  return res;
};

export const outcomeNameFormatter = (
  marketType,
  marketName,
  oddValue,
  outcomeDesc,
  sessionRuns
) => {
  let res = '-';
  switch (marketType) {
    case 'FANCY':
      res = marketName + ' @ ' + Number(oddValue * 100 - 100).toFixed(0);
      break;
    case 'BINARY':
      res = outcomeDesc + ' @ ' + Number(sessionRuns).toFixed(0);
      break;
    default:
      res = outcomeDesc;
  }

  return res;
};

export const ApprovalStatusMap: { [key: string]: string } = {
  APPROVAL_PENDING: 'Approval Pending',
  IN_PROGRESS: 'In Progress',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  INITIATED: 'Initiated',
  CANCELLED: 'Cancelled',
};
export const BonusCategoriesMap: { [key: string]: string } = {
  JOINING_BONUS: 'Joining bonus',
  DEPOSIT_BONUS: 'Deposit bonus',
  SIGNUP_BONUS: 'Sign up bonus',
  LOSSBACK_BONUS: 'Lossback bonus',
  REFERRAL_BONUS: 'Referral bonus',
};

export const bonusCategories = [
  { value: 'JOINING_BONUS', name: 'Joining bonus' },
  { value: 'DEPOSIT_BONUS', name: 'Deposit bonus' },
  { value: 'SIGNUP_BONUS', name: 'Sign up bonus' },
  { value: 'LOSSBACK_BONUS', name: 'Lossback bonus' },
  { value: 'REFERRAL_BONUS', name: 'Referral bonus' },
];
export function getBrandName() {
  return BRAND_NAME.toLocaleLowerCase();
}

// report urls which are allowed only for specific domain called icasino247.com
const reportUrls = [
  'daily_report',
  'agent_commission_report',
  'transaction_report',
];

export const shouldAllow = (url) =>
  BRAND_DOMAIN.toLowerCase().includes('icasino247.com') ||
  !reportUrls.includes(url);

export enum Jwt_token {
  ACCOUNT_ID = 'aid',
  HOUSE_ID = 'hid',
  SAP_TOKEN = 'sap',
  CURRENCY_TYPE = 'cur',
  PERMISSION = 'perm',
  ROLE = 'role',
  SUBJECT_NAME = 'sub',
  OWNER_NAME = 'owner',
  ACCOUNT_PATH = 'path',
  AFFILIATE_PATH = 'affPath',
  STATUS = 'sts',
}

export enum Role {
  SDM = 'Super-Duper-Master',
  DM = 'Duper-Master',
  SM = 'Super-Master',
  AGENT = 'Agent',
  USER = 'User',
  OPERATOR = 'Operator'
}

export enum AffiliateRole {
  MA = 'Master-Affiliate',
  SA = 'Sub-Affiliate',
  User = 'User',
}

export const AffiliateRoleNameMap = {
  'Master-Affiliate': 'MA',
  'Sub-Affiliate': 'SA',
  User: 'User',
};

export enum DownLoadFormat {
  PDF = 'application/pdf',
  EXCEL = 'application/excel',
  JSON = 'application/json',
}

export const swapArrayElements = (arr: any[], i: number, j: number) => {
  const elem = arr[i];
  arr[i] = arr[j];
  arr[j] = elem;
  return arr;
};

export enum jwt_token {
  ACCOUNT_ID = 'aid',
  HOUSE_ID = 'hid',
  SAP_TOKEN = 'sap',
  CURRENCY_TYPE = 'cur',
  PERMISSION = 'perm',
  ROLE = 'role',
  SUBJECT_NAME = 'sub',
  OWNER_NAME = 'owner',
  ACCOUNT_PATH = 'path',
  AFFILIATE_PATH = 'affPath',
}

export const domainsWithSignUpPopUp = [
  '1xcrash',
  'wincasino247',
  'cardcasino',
  'taash247',
];

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
}

export const transformVarNameToLabel = (varName: string) => {
  if (varName.includes('_')) {
    let labelName = varName.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : ' ' + d.toUpperCase()
    );
    return capitalizeFirstLetter(labelName);
  } else {
    return capitalizeFirstLetter(varName);
  }
};

export const isDivisionPresent = (divisions, name) => {
  return getDivisionData(divisions, name) ? true : false;
}

export const isSubDivisionPresent = (divisions, subDivisionName) => {
  let subDivisionData: any;
  divisions?.forEach((division: any) => {
    division?.subDivisions?.forEach((subDivision: any) => {
      if (subDivision?.subDivisionType.toLowerCase() === subDivisionName.toLowerCase() && subDivision?.enabled) {
        subDivisionData = subDivision;
      }
    })
  })
  return subDivisionData ? true : false;
}

export const getDivisionData = (divisions, name) => {
  let data = divisions?.find((item) => {
    if (item?.divisionType.toLowerCase() === name.toLowerCase() && item?.enabled) return item;
  });
  return data;
}

export const getActionData = (divisions, name) => {
  return divisions?.find((item) => {
    if (item?.name.toLowerCase() === name.toLowerCase() && item?.enabled) return item;
  });
}

export const getSubDivisionData = (divisions, divisionName, subDivisionName) => {
  let divisionData = getDivisionData(divisions, divisionName);
  let data = divisionData?.subDivisions?.find((item) => {
    if (item?.subDivisionType.toLowerCase() === subDivisionName.toLowerCase() && item?.enabled) return item;
  });
  return data;
}

export const checkAction = (divisions, type, subDivisionName, action) => {
  let subDivisionData: any;
  divisions?.forEach((division: any) => {
    division?.subDivisions?.forEach((subDivision: any) => {
      if (subDivision?.subDivisionType.toLowerCase() === subDivisionName.toLowerCase() && subDivision?.enabled) {
        subDivisionData = subDivision;
      }
    })
  })
  return getActionData(subDivisionData[type], action) ? true : false;
}

export const checkButton = (divisions, divisionName, subDivisionName, button) => {
  let divisionData = getDivisionData(divisions, divisionName);
  let subDivisionData = getDivisionData(divisionData?.subDivisions, subDivisionName);
  return getDivisionData(subDivisionData?.buttonConfigs, button) ? true : false;
}
