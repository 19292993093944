import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import sportsBookReducer from './sportsBook/sportsBookReducer';
import betslipReducer from './betslip/betslipReducer';
import webSocketReducer from './webSocket/webSocketReducer';
import commonReducer from './common/commonReducer';
import exchangeSportsReducer from './exchangeSports/exchangeSportsReducer';
import exchBetslipReducer from './exchBetslip/exchBetslipReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import casinoReducer from './casino/CasinoGameReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  sportsBook: sportsBookReducer,
  betslip: betslipReducer,
  webSocket: webSocketReducer,
  common: commonReducer,
  exchangeSports: exchangeSportsReducer,
  exchBetslip: exchBetslipReducer,
  dashboard: dashboardReducer,
  casino: casinoReducer,
});

export default rootReducer;
