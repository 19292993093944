import moment from 'moment';

import {
  SportEventMap,
  ExchangeSportEventMap,
} from '../../models/SportsBookState';
import {
  SportEventInfoData,
  MarketOddsData,
  OddsInfo,
} from '../../models/SportEventInfoData';
import { Tournament } from '../../models/Tournament';
import { ExchangeEventFancyMarketData } from '../../models/ExchangeSportEventInfoData';

export const getDefaultEvents = (sportEvents: SportEventMap) => {
  const defaultEvents: SportEventInfoData[] = [];
  const sportMap = { ...sportEvents['sr:sport:21'] };
  for (const t in sportMap) {
    const tournamentEvents = Object.values(sportMap[t]);
    defaultEvents.push(...tournamentEvents);
  }

  const result = defaultEvents.sort(compareEventsByDateAsc);

  return result;
};

export const getEventsBySport = (
  sportEvents: SportEventMap,
  sportId: string
) => {
  const tournamentMap = { ...sportEvents[sportId] };
  const sportEventResult: SportEventInfoData[] = [];
  for (const t in tournamentMap) {
    const tournamentEvents = Object.values(tournamentMap[t]);
    sportEventResult.push(...tournamentEvents);
  }

  const result = sportEventResult.sort(compareEventsByDateAsc);

  return result;
};

export const getFilteredSportEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string,
  filter: 'all' | 'live' | 'today' | 'tomorrow' | 'upcoming'
): SportEventInfoData[] => {
  switch (filter) {
    case 'all':
      return getEventsByTournament(sportEvents, sportId, tournamentId);
    case 'live':
      return getLiveEventsByTournament(sportEvents, sportId, tournamentId);
    case 'today':
      return getTodayEventsByTournament(sportEvents, sportId, tournamentId);
    case 'tomorrow':
      return getTomorrowEventsByTournament(sportEvents, sportId, tournamentId);
    case 'upcoming':
      return getUpcomingEventsByTournament(sportEvents, sportId, tournamentId);
    default:
      return getEventsByTournament(sportEvents, sportId, tournamentId);
  }
};

export const getEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string
) => {
  if (sportEvents[sportId]) {
    const eventMap = { ...sportEvents[sportId][tournamentId] };
    let sportEventResult: SportEventInfoData[] = Object.values(eventMap);

    sportEventResult = getSportEventsWithActiveMarkets(sportEventResult);

    const result = sportEventResult.sort(compareEventsByDateAsc);

    return result;
  } else {
    return [];
  }
};

const getLiveEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string
) => {
  if (sportEvents[sportId]) {
    const eventMap = { ...sportEvents[sportId][tournamentId] };
    let sportEventResult: SportEventInfoData[] = Object.values(eventMap);

    sportEventResult = getSportEventsWithActiveMarkets(sportEventResult);

    const result = sportEventResult
      .filter((se) => se.live)
      .sort(compareEventsByDateAsc);

    return result;
  } else {
    return [];
  }
};

const getTodayEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string
) => {
  if (sportEvents[sportId]) {
    const eventMap = { ...sportEvents[sportId][tournamentId] };
    let sportEventResult: SportEventInfoData[] = Object.values(eventMap);

    sportEventResult = getSportEventsWithActiveMarkets(sportEventResult);

    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');

    const result = sportEventResult
      .filter(
        (se) =>
          moment(se.scheduledTime).isSameOrAfter(todayStart) &&
          moment(se.scheduledTime).isBefore(todayEnd)
      )
      .sort(compareEventsByDateAsc);

    return result;
  } else {
    return [];
  }
};

const getTomorrowEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string
) => {
  if (sportEvents[sportId]) {
    const eventMap = { ...sportEvents[sportId][tournamentId] };
    let sportEventResult: SportEventInfoData[] = Object.values(eventMap);

    sportEventResult = getSportEventsWithActiveMarkets(sportEventResult);

    const todayStart = moment().startOf('day');
    const todayEnd = moment().endOf('day');
    const tomorrowStart = todayStart.clone().add(1, 'days');
    const tomorrowEnd = todayEnd.clone().add(1, 'days');

    const result = sportEventResult
      .filter(
        (se) =>
          moment(se.scheduledTime).isSameOrAfter(tomorrowStart) &&
          moment(se.scheduledTime).isBefore(tomorrowEnd)
      )
      .sort(compareEventsByDateAsc);
    return result;
  } else {
    return [];
  }
};

const getUpcomingEventsByTournament = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string
) => {
  if (sportEvents[sportId]) {
    const eventMap = { ...sportEvents[sportId][tournamentId] };
    let sportEventResult: SportEventInfoData[] = Object.values(eventMap);

    sportEventResult = getSportEventsWithActiveMarkets(sportEventResult);

    const todayEnd = moment().endOf('day');
    const tomorrowEnd = todayEnd.clone().add(1, 'days');

    const result = sportEventResult
      .filter((se) => moment(se.scheduledTime).isAfter(tomorrowEnd))
      .sort(compareEventsByDateAsc);

    return result;
  } else {
    return [];
  }
};

const groupMarketOutcomes = (marketOddsDataSet: MarketOddsData[]) => {
  const markets = marketOddsDataSet.sort(compareMarketDesc);
  const marketsMap = groupBy(markets, (m) => m.marketDesc);
  const marketOddsData = [];

  Array.from(marketsMap.keys()).forEach((market, i) => {
    const oddsInfo: OddsInfo[] = [];
    for (let m of marketsMap.get(market)) {
      for (let o of m.oddsInfo) {
        let oddInfo: OddsInfo = {
          description: o.description ? o.description : null,
          marketId: m.marketId,
          oddsValue: o.oddsValue ? o.oddsValue : null,
          outcomeId: o.outcomeId ? o.outcomeId : null,
          isActive: m.isActive,
        };

        oddsInfo.push(oddInfo);
      }
    }
    oddsInfo.sort((a, b) => {
      return a.outcomeId > b.outcomeId ? 1 : a.outcomeId < b.outcomeId ? -1 : 0;
    });

    const marketData: MarketOddsData = {
      isActive: marketsMap.get(market)[0].isActive,
      marketDesc: marketsMap.get(market)[0].marketDesc,
      marketId: marketsMap.get(market)[0].marketId,
      oddsInfo,
    };

    marketOddsData.push(marketData);
  });
  return marketOddsData;
};

export const getEvent = (
  sportEvents: SportEventMap,
  sportId: string,
  tournamentId: string,
  eventId: string
) => {
  if (
    sportEvents[sportId] &&
    sportEvents[sportId][tournamentId] &&
    sportEvents[sportId][tournamentId][eventId]
  ) {
    let event = { ...sportEvents[sportId][tournamentId][eventId] };
    event.marketOddsDataSet = groupMarketOutcomes(event.marketOddsDataSet);

    return event;
  } else {
    return null;
  }
};

const prioritizeFancyMarkets = (
  markets,
  homeTeam: string,
  awayTeam: string
) => {
  let ballByBallmarkets: MarketOddsData[] = [];
  let overToOverMarkets: MarketOddsData[] = [];
  let overSpecificMarkets: MarketOddsData[] = [];
  let playerScoreMarkets: MarketOddsData[] = [];
  let playerTotalMarkets: MarketOddsData[] = [];
  let totalSetMarkets: MarketOddsData[] = [];
  let homeTeamMarkets: MarketOddsData[] = [];
  let awayTeamMarkets: MarketOddsData[] = [];
  let dismissalMarkets: MarketOddsData[] = [];
  let playerPerformanceMarkets: MarketOddsData[] = [];
  let otherMarkets: MarketOddsData[] = [];
  for (const m of markets) {
    const desc = m.marketDesc.toLowerCase();
    if (
      !desc.startsWith('overs') &&
      desc.startsWith('over') &&
      desc.includes('delivery')
    ) {
      ballByBallmarkets.push(m);
    } else if (desc.startsWith('overs') && desc.includes('total')) {
      overToOverMarkets.push(m);
    } else if (
      !desc.startsWith('overs') &&
      desc.startsWith('over') &&
      desc.includes('total')
    ) {
      overSpecificMarkets.push(m);
    } else if (desc.includes('to score')) {
      playerScoreMarkets.push(m);
    } else if (desc.endsWith('total')) {
      playerTotalMarkets.push(m);
    } else if (desc.startsWith('total')) {
      totalSetMarkets.push(m);
    } else if (
      desc.startsWith(homeTeam.toLowerCase()) &&
      desc.includes('total')
    ) {
      homeTeamMarkets.push(m);
    } else if (
      desc.startsWith(awayTeam.toLowerCase()) &&
      desc.includes('total')
    ) {
      awayTeamMarkets.push(m);
    } else if (desc.includes('dismissal')) {
      dismissalMarkets.push(m);
    } else if (desc.includes('player performane')) {
      playerPerformanceMarkets.push(m);
    } else {
      otherMarkets.push(m);
    }
  }
  // Sort categorized markets.
  ballByBallmarkets = ballByBallmarkets.sort(compareMarketDesc);
  overToOverMarkets.sort((a, b) => {
    const descA = Number(a.marketDesc.split(' ')[3]);
    const descB = Number(b.marketDesc.split(' ')[3]);
    return descA > descB ? 1 : descA < descB ? -1 : 0;
  });
  overSpecificMarkets = overSpecificMarkets.sort(compareMarketDesc);
  playerScoreMarkets = playerScoreMarkets.sort(compareMarketDesc);
  playerTotalMarkets = playerTotalMarkets.sort(compareMarketDesc);
  totalSetMarkets = totalSetMarkets.sort(compareMarketDesc);
  homeTeamMarkets = homeTeamMarkets.sort(compareMarketDesc);
  awayTeamMarkets = awayTeamMarkets.sort(compareMarketDesc);
  dismissalMarkets = dismissalMarkets.sort(compareMarketDesc);
  playerPerformanceMarkets = playerPerformanceMarkets.sort(compareMarketDesc);
  otherMarkets = otherMarkets.sort(compareMarketDesc);
  let fancyMarketHashMap: Map<string, ExchangeEventFancyMarketData> | {} = {};
  fancyMarketHashMap['ballByBall'] = {
    marketName: 'Ball By Ball',
    marketOddsDataSet: ballByBallmarkets,
  };
  fancyMarketHashMap['overToOver'] = {
    marketName: 'Over To Over',
    marketOddsDataSet: overToOverMarkets,
  };
  fancyMarketHashMap['overByOver'] = {
    marketName: 'Over By Over',
    marketOddsDataSet: overSpecificMarkets,
  };
  fancyMarketHashMap['playerScore'] = {
    marketName: "Player's Score",
    marketOddsDataSet: playerScoreMarkets,
  };
  fancyMarketHashMap['playerTotal'] = {
    marketName: "Player's Total",
    marketOddsDataSet: playerTotalMarkets,
  };
  fancyMarketHashMap['totalSet'] = {
    marketName: 'Total',
    marketOddsDataSet: totalSetMarkets,
  };
  fancyMarketHashMap['homeTeam'] = {
    marketName: homeTeam,
    marketOddsDataSet: homeTeamMarkets,
  };
  fancyMarketHashMap['awayTeam'] = {
    marketName: awayTeam,
    marketOddsDataSet: awayTeamMarkets,
  };
  fancyMarketHashMap['dismissal'] = {
    marketName: 'Fall of Wickets',
    marketOddsDataSet: dismissalMarkets,
  };
  fancyMarketHashMap['playerPerformance'] = {
    marketName: "Player's Performance",
    marketOddsDataSet: playerPerformanceMarkets,
  };
  fancyMarketHashMap['other'] = {
    marketName: 'Other',
    marketOddsDataSet: otherMarkets,
  };
  return fancyMarketHashMap;
};

const prioritizeMarketOddsData = (
  markets,
  homeTeam: string,
  awayTeam: string
) => {
  let ballByBallmarkets: MarketOddsData[] = [];
  let overToOverMarkets: MarketOddsData[] = [];
  let overSpecificMarkets: MarketOddsData[] = [];
  let playerScoreMarkets: MarketOddsData[] = [];
  let playerTotalMarkets: MarketOddsData[] = [];
  let totalSetMarkets: MarketOddsData[] = [];
  let homeTeamMarkets: MarketOddsData[] = [];
  let awayTeamMarkets: MarketOddsData[] = [];
  let dismissalMarkets: MarketOddsData[] = [];
  let playerPerformanceMarkets: MarketOddsData[] = [];
  let otherMarkets: MarketOddsData[] = [];
  for (const m of markets) {
    const desc = m.marketDesc.toLowerCase();
    if (
      !desc.startsWith('overs') &&
      desc.startsWith('over') &&
      desc.includes('delivery')
    ) {
      ballByBallmarkets.push(m);
    } else if (desc.startsWith('overs') && desc.includes('total')) {
      overToOverMarkets.push(m);
    } else if (
      !desc.startsWith('overs') &&
      desc.startsWith('over') &&
      desc.includes('total')
    ) {
      overSpecificMarkets.push(m);
    } else if (desc.includes('to score')) {
      playerScoreMarkets.push(m);
    } else if (desc.endsWith('total')) {
      playerTotalMarkets.push(m);
    } else if (desc.startsWith('total')) {
      totalSetMarkets.push(m);
    } else if (
      desc.startsWith(homeTeam.toLowerCase()) &&
      desc.includes('total')
    ) {
      homeTeamMarkets.push(m);
    } else if (
      desc.startsWith(awayTeam.toLowerCase()) &&
      desc.includes('total')
    ) {
      awayTeamMarkets.push(m);
    } else if (desc.includes('dismissal')) {
      dismissalMarkets.push(m);
    } else if (desc.includes('player performane')) {
      playerPerformanceMarkets.push(m);
    } else {
      otherMarkets.push(m);
    }
  }
  // Sort categorized markets.
  ballByBallmarkets = ballByBallmarkets.sort(compareMarketDesc);
  overToOverMarkets.sort((a, b) => {
    const descA = Number(a.marketDesc.split(' ')[3]);
    const descB = Number(b.marketDesc.split(' ')[3]);
    return descA > descB ? 1 : descA < descB ? -1 : 0;
  });
  overSpecificMarkets = overSpecificMarkets.sort(compareMarketDesc);
  playerScoreMarkets = playerScoreMarkets.sort(compareMarketDesc);
  playerTotalMarkets = playerTotalMarkets.sort(compareMarketDesc);
  totalSetMarkets = totalSetMarkets.sort(compareMarketDesc);
  homeTeamMarkets = homeTeamMarkets.sort(compareMarketDesc);
  awayTeamMarkets = awayTeamMarkets.sort(compareMarketDesc);
  dismissalMarkets = dismissalMarkets.sort(compareMarketDesc);
  playerPerformanceMarkets = playerPerformanceMarkets.sort(compareMarketDesc);
  otherMarkets = otherMarkets.sort(compareMarketDesc);

  return ballByBallmarkets
    .concat(overToOverMarkets)
    .concat(overSpecificMarkets)
    .concat(playerScoreMarkets)
    .concat(playerTotalMarkets)
    .concat(totalSetMarkets)
    .concat(homeTeamMarkets)
    .concat(awayTeamMarkets)
    .concat(dismissalMarkets)
    .concat(playerPerformanceMarkets)
    .concat(otherMarkets);
};

export const getExchangeEvent = (
  sportEvents: ExchangeSportEventMap,
  sportId: string,
  tournamentId: string,
  eventId: string
) => {
  if (
    sportEvents[sportId] &&
    sportEvents[sportId][tournamentId] &&
    sportEvents[sportId][tournamentId][eventId]
  ) {
    const event = { ...sportEvents[sportId][tournamentId][eventId] };
    event.marketOddsDataSet = groupMarketOutcomes(event.marketOddsDataSet);

    const sbMarkets = event.marketOddsDataSet;
    const fancyMarkets: MarketOddsData[] = [];

    for (let i = 0; i < sbMarkets.length; i += 1) {
      if (
        sbMarkets[i].marketDesc.toLowerCase().includes('to score') ||
        sbMarkets[i].marketDesc.toLowerCase().includes('dismissal') ||
        sbMarkets[i].marketDesc.toLowerCase().includes('innings over') ||
        sbMarkets[i].marketDesc.toLowerCase().includes('total')
      ) {
        let addThisMarket = false;
        sbMarkets[i].marketDesc = sbMarkets[i].marketDesc.includes(
          '1st innings -'
        )
          ? sbMarkets[i].marketDesc.replace('1st innings -', '').trim()
          : sbMarkets[i].marketDesc.includes('1st innings')
          ? sbMarkets[i].marketDesc.replace('1st innings', '').trim()
          : sbMarkets[i].marketDesc;
        for (let j = 0; j < sbMarkets[i].oddsInfo.length; j += 1) {
          if (
            (sbMarkets[i].oddsInfo.length === 2 &&
              (sbMarkets[i].oddsInfo[j].description === 'yes' ||
                sbMarkets[i].oddsInfo[j].description === 'no')) ||
            sbMarkets[i].oddsInfo[j].description
              .toLowerCase()
              .startsWith('under') ||
            sbMarkets[i].oddsInfo[j].description
              .toLowerCase()
              .startsWith('over')
          ) {
            if (
              sbMarkets[i].marketDesc.toLocaleLowerCase().includes('to score')
            ) {
              const wordsArr = sbMarkets[i].marketDesc.split(' ');
              sbMarkets[i].oddsInfo[j].metaDesc = wordsArr[wordsArr.length - 1];
              addThisMarket = true;
            }
            if (
              sbMarkets[i].marketDesc.toLocaleLowerCase().includes('dismissal')
            ) {
              const wordsArr = sbMarkets[i].marketDesc.split(' ');
              sbMarkets[i].oddsInfo[j].metaDesc = wordsArr[1];
              addThisMarket = true;
            }
            if (
              sbMarkets[i].oddsInfo[j].description
                .toLocaleLowerCase()
                .startsWith('under')
            ) {
              const wordsArr = sbMarkets[i].oddsInfo[j].description.split(' ');
              sbMarkets[i].oddsInfo[j].metaDesc = wordsArr[wordsArr.length - 1];
              sbMarkets[i].oddsInfo[j].metaDesc = String(
                Number(wordsArr[wordsArr.length - 1]) + 0.5
              );
              addThisMarket = true;
            }
            if (
              sbMarkets[i].oddsInfo[j].description
                .toLocaleLowerCase()
                .startsWith('over')
            ) {
              const wordsArr = sbMarkets[i].oddsInfo[j].description.split(' ');
              sbMarkets[i].oddsInfo[j].metaDesc = wordsArr[wordsArr.length - 1];
              sbMarkets[i].oddsInfo[j].metaDesc = String(
                Number(wordsArr[wordsArr.length - 1]) + 0.5
              );
              addThisMarket = true;
            }
          }
        }
        if (addThisMarket) fancyMarkets.push(sbMarkets[i]);
      }
    }

    if (event.sportId === 'sr:sport:21') {
      // Prioritize Fancy Markets.
      event.fancyMarketHashMap = prioritizeFancyMarkets(
        fancyMarkets,
        event.homeTeam,
        event.awayTeam
      );
      event.marketOddsDataSet = prioritizeMarketOddsData(
        event.marketOddsDataSet,
        event.homeTeam,
        event.awayTeam
      );
    }
    return event;
  } else {
    return null;
  }
};

const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const compareEventsByDateAsc = (
  a: SportEventInfoData,
  b: SportEventInfoData
) => {
  const dateA = moment(a.scheduledTime);
  const dateB = moment(b.scheduledTime);

  let comparison = 0;
  if (dateA.isAfter(dateB)) {
    comparison = 1;
  } else if (dateA.isSameOrBefore(dateB)) {
    comparison = -1;
  }
  return comparison;
};

const getSportEventsWithActiveMarkets = (se: SportEventInfoData[]) => {
  return se
    .map((se) => {
      return {
        ...se,
        marketOddsDataSet: se.marketOddsDataSet.filter(
          (m) => m.isActive === 'Active' || 'Suspended'
        ),
      };
    })
    .filter((se) => se.marketOddsDataSet && se.marketOddsDataSet.length > 0);
};

const compareMarketDesc = (a: MarketOddsData, b: MarketOddsData) => {
  const descA = a.marketDesc;
  const descB = b.marketDesc;

  let comparison = 0;
  if (descA.toLowerCase() > descB.toLowerCase()) {
    comparison = 1;
  } else if (descA.toLowerCase() < descB.toLowerCase()) {
    comparison = -1;
  }

  return comparison;
};

export const containsActiveMarket = (se: SportEventInfoData): boolean => {
  const index = se.marketOddsDataSet.findIndex(
    (m) => m.isActive === 'Active' || 'Suspended'
  );

  return index === -1 ? false : true;
};

export const updateCurrentActiveTournaments = (
  currentActiveTournaments: Tournament[],
  se: SportEventInfoData
) => {
  if (currentActiveTournaments && currentActiveTournaments.length > 0) {
    const foundIndex = currentActiveTournaments.findIndex(
      (t) => t.id === se.tournamentId
    );
    if (foundIndex === -1) {
      if (containsActiveMarket(se)) {
        currentActiveTournaments.push({
          id: se.tournamentId,
          name: se.tournamentName,
          sportId: se.sportId,
          eventCount: 1,
          categoryId: se.categoryId,
          categoryName: se.categoryName,
        });
      }
    }
  } else {
    if (containsActiveMarket(se)) {
      currentActiveTournaments = [];
      currentActiveTournaments.push({
        id: se.tournamentId,
        name: se.tournamentName,
        sportId: se.sportId,
        eventCount: 1,
        categoryId: se.categoryId,
        categoryName: se.categoryName,
      });
    }
  }

  return currentActiveTournaments;
};
