import API from '../../api';
import {
  FETCH_SPORT_EVENTS_SUCCESS,
  REQUEST_FAILED,
  FETCH_ACTIVE_SPORTS_SUCCESS,
  FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START,
  FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS,
  FEATCH_SPORTS_SUCCESS,
  FEATCH_TOURN_BY_SPORT_SUCCESS,
  PRODUCER_STATUS_CHANGE,
  REQUEST_START,
  ODDS_CHANGE,
  BET_STOP,
  FILTER_CHANGE,
  SPORT_CHANGE,
  TOURNAMENT_CHANGE,
  EVENT_CHANGE,
  UPDATE_LIVE_PRODUCER_STATUS,
  UPDATE_SCORE,
  FETCH_ACTIVE_BETS,
  FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS,
  EXCHANGE_ODDS_CHANGE,
} from './sportsBookActionTypes';
import { SportEventInfoData } from '../../models/SportEventInfoData';
import { BetStopPayload } from '../../models/BetStopPayload';
import { ProducerInfo } from '../../models/ProducerInfo';
import { ScoreUpdate } from '../../models/ScoreUpdate';
import {
  ExchangeSportEventInfoData,
  ExchangeSportEventWSMsgData,
  BookmakerPercentageData,
} from '../../models/ExchangeSportEventInfoData';

declare const sessionStorage: any;

const requestStart = () => {
  return {
    type: REQUEST_START,
  };
};

export const updateCricketScore = (scoreUpdate: ScoreUpdate) => {
  return {
    type: UPDATE_SCORE,
    payload: scoreUpdate,
  };
};

const fetchActiveTournBySportReqStart = () => {
  return {
    type: FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START,
  };
};

const fetchSportEventsSuccess = (response) => {
  return {
    type: FETCH_SPORT_EVENTS_SUCCESS,
    payload: response,
  };
};

const fetchExchangeSportEventsSuccess = (response) => {
  return {
    type: FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS,
    payload: response,
  };
};

const fetchSportsSuccess = (response) => {
  return {
    type: FEATCH_SPORTS_SUCCESS,
    payload: response,
  };
};

const fetchActiveSportsSuccess = (response) => {
  return {
    type: FETCH_ACTIVE_SPORTS_SUCCESS,
    payload: response,
  };
};

const fetchTurnamentsBySportSuccess = (response) => {
  return {
    type: FEATCH_TOURN_BY_SPORT_SUCCESS,
    payload: response,
  };
};

const fetchActiveTurnamentsBySportSuccess = (response) => {
  return {
    type: FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS,
    payload: response,
  };
};

const fetchRequestFailed = (err: string) => {
  return {
    type: REQUEST_FAILED,
    payload: err,
  };
};

export const fetchMarketOdds = (
  pageNo: number,
  filter: string,
  sportID?: string,
  TournamentID?: string
) => {
  // Cricket is default sport
  let url = '/SportEventInfo/sr:sport:21';
  if (TournamentID) {
    url = `/SportEventInfo/${sportID}/${TournamentID}`;
  } else if (sportID) {
    url = `/SportEventInfo/${sportID}`;
  }

  return async (dispatch: Function) => {
    try {
      dispatch(requestStart());
      const response = await API.get(url, {
        params: {
          pageNo: 1,
          filter: filter,
          pageSize: 50,
        },
      });
      if (response.status === 200) {
        dispatch(fetchSportEventsSuccess(response.data.sportEventInfoData));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchSports = () => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response = await API.get('/sports');

      if (response.status === 200) {
        dispatch(fetchSportsSuccess(response.data));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchActiveSports = () => {
  return async (dispatch: Function) => {
    try {
      dispatch(requestStart());
      const response = await API.get('/active-sports');

      if (response.status === 200) {
        dispatch(fetchActiveSportsSuccess(response.data));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchActiveTurnamentsBySport = (sid: string, filter: string) => {
  return async (dispatch: Function) => {
    try {
      dispatch(fetchActiveTournBySportReqStart());
      const response = await API.get(`/sports/${sid}/active-tournaments`, {
        params: {
          filter: filter,
        },
      });

      if (response.status === 200) {
        let res = { sid: sid, data: response.data };
        dispatch(fetchActiveTurnamentsBySportSuccess(res));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchTurnamentsBySport = (sid: string) => {
  return async (dispatch: Function) => {
    dispatch(requestStart());
    try {
      const response = await API.get(`/sports/${sid}/tournaments`);

      if (response.status === 200) {
        dispatch(fetchTurnamentsBySportSuccess(response.data));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const updateSportEventInfo = (sportEventInfo: SportEventInfoData) => {
  return {
    type: ODDS_CHANGE,
    payload: sportEventInfo,
  };
};

export const updateExchangeSportEventInfo = (
  exchMarketsData: ExchangeSportEventWSMsgData
) => {
  return {
    type: EXCHANGE_ODDS_CHANGE,
    payload: exchMarketsData,
  };
};

export const betStop = (betStopPayload: BetStopPayload) => {
  return {
    type: BET_STOP,
    payload: betStopPayload,
  };
};

export const updateProducer = (updateProducer: ProducerInfo) => {
  return {
    type: PRODUCER_STATUS_CHANGE,
    payload: updateProducer,
  };
};

export const updateLiveProducerStatus = (status: boolean) => {
  return {
    type: UPDATE_LIVE_PRODUCER_STATUS,
    payload: status,
  };
};

export const setFetchActiveBets = (status: boolean) => {
  return {
    type: FETCH_ACTIVE_BETS,
    payload: status,
  };
};

export const setFilter = (
  filter: 'all' | 'live' | 'today' | 'tomorrow' | 'upcoming'
) => {
  return {
    type: FILTER_CHANGE,
    payload: filter,
  };
};

export const setSport = (sport: { id: string; name: string; slug: string }) => {
  return {
    type: SPORT_CHANGE,
    payload: sport,
  };
};

export const setTournament = (tournament: {
  id: string;
  name: string;
  slug: string;
}) => {
  return {
    type: TOURNAMENT_CHANGE,
    payload: tournament,
  };
};

export const setEvent = (event: { id: string; name: string; slug: string }) => {
  return {
    type: EVENT_CHANGE,
    payload: event,
  };
};

export const fetchSportEventInfoByEventId = (
  sport: string,
  tournament: string,
  event: string
) => {
  return async (dispatch: Function) => {
    try {
      const eName = event.substring(0, event.lastIndexOf('-'));
      const eId = window.atob(
        event.substring(event.lastIndexOf('-') + 1, event.length)
      );
      dispatch(requestStart());
      const response = await API.get(`/SportEventAllMarkets/${eId}`);
      if (response.status === 200) {
        const res: {
          sportEventInfoData: SportEventInfoData;
          sportName: string;
        } = response.data;
        dispatch(
          setEvent({
            id: res.sportEventInfoData.eventId,
            name: res.sportEventInfoData.eventName,
            slug: eName,
          })
        );
        dispatch(
          setTournament({
            id: res.sportEventInfoData.tournamentId,
            name: res.sportEventInfoData.tournamentName,
            slug: tournament,
          })
        );
        dispatch(
          setSport({
            id: res.sportEventInfoData.sportId,
            name: res.sportName,
            slug: sport,
          })
        );
        dispatch(fetchSportEventsSuccess([res.sportEventInfoData]));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchExchangeSportEventInfoByEventId = (
  sport: string,
  tournament: string,
  event: string
) => {
  return async (dispatch: Function) => {
    try {
      const eName = event.substring(0, event.lastIndexOf('-'));
      const eId = window.atob(
        event.substring(event.lastIndexOf('-') + 1, event.length)
      );
      dispatch(requestStart());
      const response = await API.get(`/exchange/${eId}`);
      if (response.status === 200) {
        const res: {
          exchangeSportEvent: ExchangeSportEventInfoData;
          sportName: string;
        } = response.data;
        dispatch(
          setEvent({
            id: res.exchangeSportEvent.eventId,
            name: res.exchangeSportEvent.eventName,
            slug: eName,
          })
        );
        dispatch(
          setTournament({
            id: res.exchangeSportEvent.tournamentId,
            name: res.exchangeSportEvent.tournamentName,
            slug: tournament,
          })
        );
        dispatch(
          setSport({
            id: res.exchangeSportEvent.sportId,
            name: res.sportName,
            slug: sport,
          })
        );
        dispatch(fetchExchangeSportEventsSuccess([res.exchangeSportEvent]));
      } else {
        throw new Error(response.data);
      }
    } catch (err) {
      dispatch(fetchRequestFailed(err.messages));
      if (err.response && err.response.status === 401) {
        //dispatch(logout());
      }
    }
  };
};

export const fetchEventBookmakerPercentage = (eventId: string) => {
  return async (dispatch: Function) => {
    try {
      const response = await API.post(
        '/user/bookmaker-events/bookmaker-market-details',
        { eventId: eventId },
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      dispatch(fetchEventBookmakerPercentageSucess(response.data));
    } catch (err) {
      dispatch(fetchRequestFailed('err'));
    }
  };
};

export const fetchEventBookmakerPercentageSucess = (
  bmData: BookmakerPercentageData
) => {
  return {
    type: 'FETCH_BOOKMAKER_PERCENTAGE_SUCCESS',
    payload: bmData,
  };
};
