import React from 'react';
import { IonImg } from '@ionic/react';
import Card from '@material-ui/core/Card';

import webtitleImg from '../../assets/images/brand/title.png';
import './MFA.scss';
import EnableMFA from './EnableMFA';

const MFA: React.FC = () => {
  return (
    <div className="bg">
      <div className="brand-logo">
        <img className="brand-logo-img" src={webtitleImg} />
      </div>
        <Card className="card">
          <EnableMFA />
        </Card>
    </div>
  );
};

export default MFA;
