import { BetSlip } from '../../models/BetSlip';
import {
  SET_BETS,
  SET_MULTI_BET_STAKE_POINTS,
  SET_ACCEPT_NEW_ODDS,
  TOGGLE_BETSLIP,
  CLEAR_BETS,
  FETCH_STAKE_LIMITS_SUCCESS,
  FETCH_STAKE_LIMITS_FAILED,
  SET_QB_ACTIVE,
} from './betslipActionTypes';
import { PlaceBetRequest } from '../../models//api/PlaceBetRequest';

type Action = {
  type: string;
  payload: any;
};

const initialState: BetSlip = {
  bets: [],
  multiBetStakePoints: null,
  acceptNewOdds: false,
  minimizeBetslip: true,
  minStakeSB: 0,
  maxStakeSB: 0,
  qbActive: null,
};

const betslipReducer = (state = initialState, action: Action): BetSlip => {
  switch (action.type) {
    case SET_QB_ACTIVE:
      return {
        ...state,
        qbActive: action.payload,
      };
    case SET_BETS: {
      return {
        ...state,
        bets: action.payload,
      };
    }
    case SET_MULTI_BET_STAKE_POINTS: {
      const points = action.payload ? action.payload : null;
      return {
        ...state,
        multiBetStakePoints: points,
      };
    }
    case SET_ACCEPT_NEW_ODDS: {
      return {
        ...state,
        acceptNewOdds: action.payload,
      };
    }
    case TOGGLE_BETSLIP: {
      return {
        ...state,
        minimizeBetslip: action.payload,
      };
    }
    case CLEAR_BETS: {
      const oid: string = action.payload;
      if (oid === 'all') {
        return {
          ...state,
          minimizeBetslip: true,
          acceptNewOdds: false,
          bets: [],
        };
      }
      let betRequests = [...state.bets];
      betRequests = betRequests.filter(
        (req: PlaceBetRequest) => req.outcomeId !== oid
      );
      if (betRequests.length === 0) {
        return {
          ...state,
          minimizeBetslip: true,
          acceptNewOdds: false,
          bets: [],
        };
      }
      return {
        ...state,
        bets: betRequests,
      };
    }

    case FETCH_STAKE_LIMITS_SUCCESS:
      return {
        ...state,
        minStakeSB: action.payload.minStakeSB,
        maxStakeSB: action.payload.maxStakeSB,
      };

    case FETCH_STAKE_LIMITS_FAILED:
      return {
        ...state,
        minStakeSB: null,
        maxStakeSB: null,
      };
    default:
      return state;
  }
};

export default betslipReducer;
