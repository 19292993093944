import { CasinoState } from '../../models/CasinoState';
import {
  UPDATE_IND_CASINO_GAMES, UPDATE_CASINO_GAMES,
} from './CasinoActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: CasinoState = {
  indCasinoGames: [],
  casinoGames: {}
};

const casinoReducer = (
  state = initialState,
  action: Action
): CasinoState => {
  switch (action.type) {
    case UPDATE_IND_CASINO_GAMES:
      return {
        ...state,
        indCasinoGames: action.payload,
      };

      case UPDATE_CASINO_GAMES:
        return {
          ...state,
          casinoGames: action.payload,
        };
    default:
      return state;
  }
};

export default casinoReducer;
