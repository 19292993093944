import REPORTING_API from '../../reporting';
import {
  EXCH_BET_ODDS_CHANGE,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_CACHE_SUCCESS,
} from './exchBetslipActionTypes';

export const setExchangeBetStake = (
  index: number,
  amount: number,
  type: 'ADD' | 'SET'
) => {
  return {
    type: SET_EXCH_BET_STAKE,
    payload: {
      index,
      amount,
      type,
    },
  };
};

export const exchangeBetOddsChange = (index: number, odds: number) => {
  return {
    type: EXCH_BET_ODDS_CHANGE,
    payload: { index, odds },
  };
};

export const clearExchcngeBets = () => {
  return {
    type: CLEAR_EXCHANGE_BETS,
    payload: [],
  };
};

const fetchOpenBetsCacheSuccess = (result, totalOrders?: number) => {
  return {
    type: FETCH_OPEN_BETS_CACHE_SUCCESS,
    payload: {
      result: result,
      totalOrders: totalOrders ?? 0,
    },
  };
};

export const fetchOpenBetsCache = (
  eventId: string,
  username?: string,
  providerId?: string,
  marketType?: string
) => {
  return async (dispatch: Function) => {
    try {
      if (eventId) {
        REPORTING_API.get('/reports/v2/orders/:search', {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            Accept: 'application/json',
          },
          params: {
            reportType: 'OPEN_ORDERS',
            status: 'Open',
            eventId: eventId,
            userName: username ? username : null,
            marketType: marketType,
          },
        })
          .then((response) => {
            dispatch(
              fetchOpenBetsCacheSuccess(
                response.data.orders,
                response.data.totalOrders
              )
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (err) {
      dispatch(fetchOpenBetsCacheSuccess([]));
    }
  };
};
