import moment from 'moment';

import { SportsBookState } from '../../models/SportsBookState';
import { SportEventInfoData } from '../../models/SportEventInfoData';
import { Tournament } from '../../models/Tournament';
import { Sport } from '../../models/Sport';
import { SPORTS_MAP } from '../../constants/Sports';
import { SPORT_PRIORITIES_MAP } from '../../constants/SportPriorities';

import {
  FETCH_ACTIVE_SPORTS_SUCCESS,
  FEATCH_SPORTS_SUCCESS,
  FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START,
  FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS,
  FETCH_SPORT_EVENTS_SUCCESS,
  REQUEST_START,
  REQUEST_FAILED,
  ODDS_CHANGE,
  BET_STOP,
  PRODUCER_STATUS_CHANGE,
  FILTER_CHANGE,
  SPORT_CHANGE,
  TOURNAMENT_CHANGE,
  EVENT_CHANGE,
  UPDATE_LIVE_PRODUCER_STATUS,
  UPDATE_SCORE,
  FETCH_ACTIVE_BETS,
  FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS,
  EXCHANGE_ODDS_CHANGE,
} from './sportsBookActionTypes';
import { BetStopPayload } from '../../models/BetStopPayload';
import { updateCurrentActiveTournaments } from './sportsBookSelectors';
import { ProducerInfo } from '../../models/ProducerInfo';
import { ScoreUpdate } from '../../models/ScoreUpdate';
import {
  ExchangeSportEventInfoData,
  ExchangeSportEventWSMsgData,
} from '../../models/ExchangeSportEventInfoData';

type Action = {
  type: string;
  payload: any;
};

const initialState: SportsBookState = {
  errorMsg: '',
  sports: [],
  activeSports: [],
  activeTournaments: [],
  loading: false,
  sportEvents: {},
  exchangeSportEvents: {},
  fetchTournBySportProgress: false,
  filter: 'all',
  selectedSport: { id: 'sr:sport:21', name: 'Cricket', slug: 'cricket' },
  selectedTournament: { id: '', name: '', slug: '' },
  selectedEvent: { id: '', name: '', slug: '' },
  producerInfo: [],
  isLiveProducerDown: false,
  fetchActiveBets: true,
  bookmakerPercentages: {},
};

const sportsReducer = (
  state = initialState,
  action: Action
): SportsBookState => {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        loading: true,
        errorMsg: '',
      };

    case UPDATE_SCORE: {
      const sportEvents = { ...state.sportEvents };
      const se: ScoreUpdate = action.payload;
      try {
        sportEvents[se.sportId][se.tournamentId][se.eventId].cricketHomeScore =
          se.homeScore;
        sportEvents[se.sportId][se.tournamentId][se.eventId].cricketAwayScore =
          se.awayScore;
      } catch (ex) {
        console.log(ex);
      }

      return {
        ...state,
        sportEvents,
      };
    }
    case FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START:
      return {
        ...state,
        fetchTournBySportProgress: true,
        errorMsg: '',
      };

    case FETCH_SPORT_EVENTS_SUCCESS: {
      const newSportEvents: SportEventInfoData[] = action.payload;
      const sportEvents = { ...state.sportEvents };
      for (const se of newSportEvents) {
        if (se.homeTeam === null) {
          se.homeTeam = se.eventName.split(' vs. ')[0];
        }
        if (se.awayTeam === null) {
          se.awayTeam = se.eventName.split(' vs. ')[1];
        }
        se.eventSlug = se.eventName
          .toLowerCase()
          .replace('vs.', 'vs')
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        se.tournamentSlug = se.tournamentName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        if (se.sportId in sportEvents) {
          if (se.tournamentId in sportEvents[se.sportId]) {
            sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          } else {
            sportEvents[se.sportId][se.tournamentId] = {};
            sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          }
        } else {
          sportEvents[se.sportId] = {};
          sportEvents[se.sportId][se.tournamentId] = {};
          sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
        }
      }
      return {
        ...state,
        loading: false,
        errorMsg: '',
        sportEvents: sportEvents,
      };
    }

    case FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS: {
      const newSportEvents: ExchangeSportEventInfoData[] = action.payload;
      const sportEvents = { ...state.exchangeSportEvents };
      for (const se of newSportEvents) {
        if (se.homeTeam === null) {
          se.homeTeam = se.eventName.split(' vs. ')[0];
        }
        if (se.awayTeam === null) {
          se.awayTeam = se.eventName.split(' vs. ')[1];
        }
        se.eventSlug = se.eventName
          .toLowerCase()
          .replace('vs.', 'vs')
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        se.tournamentSlug = se.tournamentName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        se.fancyMarketHashMap = {};
        if (se.sportId in sportEvents) {
          if (se.tournamentId in sportEvents[se.sportId]) {
            sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          } else {
            sportEvents[se.sportId][se.tournamentId] = {};
            sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          }
        } else {
          sportEvents[se.sportId] = {};
          sportEvents[se.sportId][se.tournamentId] = {};
          sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
        }
      }
      return {
        ...state,
        loading: false,
        errorMsg: '',
        exchangeSportEvents: sportEvents,
      };
    }

    case FEATCH_SPORTS_SUCCESS:
      let allSports: Sport[] = [];
      for (let s of action.payload) {
        let sport: Sport = {
          name: s.name,
          id: s.id,
          tournaments: [],
          priority: 0,
        };
        allSports.push(sport);
      }
      return {
        ...state,
        sports: allSports,
        loading: false,
        errorMsg: '',
      };

    case FETCH_ACTIVE_SPORTS_SUCCESS:
      let activeSports: Sport[] = [];
      for (let s of action.payload) {
        const slug = s.name.split(' ').join('-').toLowerCase();
        const priority =
          s.id in SPORT_PRIORITIES_MAP ? SPORT_PRIORITIES_MAP[s.id] : 0;
        let sport: Sport = {
          name: s.name,
          id: s.id,
          slug: slug,
          tournaments: [],
          priority,
        };
        activeSports.push(sport);
      }
      activeSports.sort((a: Sport, b: Sport) => {
        const aPriority = a.priority;
        const bPriority = b.priority;
        if (a.priority === b.priority) {
          return parseInt(a.id.split(':')[2]) < parseInt(b.id.split(':')[2])
            ? -1
            : parseInt(a.id.split(':')[2]) > parseInt(b.id.split(':')[2])
            ? 1
            : 0;
        } else {
          return aPriority < bPriority ? 1 : -1;
        }
      });
      return {
        ...state,
        sports: action.payload,
        activeSports: activeSports,
        loading: false,
        errorMsg: '',
      };

    case FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS:
      const sid = action.payload.sid;
      const tournaments: Tournament[] = action.payload.data;
      let actSports = [...state.activeSports];
      if (tournaments && tournaments.length > 0) {
        tournaments.sort((a: Tournament, b: Tournament) => {
          if (!a.categoryName) a.categoryName = '';
          if (!b.categoryName) b.categoryName = '';
          return a.categoryName.toLowerCase() < b.categoryName.toLowerCase()
            ? -1
            : a.categoryName.toLowerCase() > b.categoryName.toLowerCase()
            ? 1
            : 0;
        });
        for (let s of actSports) {
          if (s.id === sid) {
            s.tournaments = [];
            for (let tournament of tournaments) {
              s.tournaments.push({
                id: tournament.id,
                name: tournament.name,
                categoryId: tournament.categoryId,
                categoryName: tournament.categoryName,
                eventCount: tournament.eventCount,
              });
            }
          }
        }
      } else {
        for (let s of actSports) {
          if (s.id === sid) {
            s.tournaments = [];
          }
        }
      }

      return {
        ...state,
        activeSports: actSports,
        activeTournaments: action.payload.data,
        fetchTournBySportProgress: false,
        errorMsg: '',
      };

    case REQUEST_FAILED:
      return {
        ...state,
        errorMsg: action.payload,
        loading: false,
        fetchTournBySportProgress: false,
      };

    case ODDS_CHANGE: {
      const se: SportEventInfoData = action.payload;
      const sportEvents = { ...state.sportEvents };

      if (se.sportId in sportEvents) {
        if (se.tournamentId in sportEvents[se.sportId]) {
          if (se.eventId in sportEvents[se.sportId][se.tournamentId]) {
            if (se.sportId !== 'sr:sport:21') {
              sportEvents[se.sportId][se.tournamentId][se.eventId].homeScore =
                se.homeScore;
              sportEvents[se.sportId][se.tournamentId][se.eventId].awayScore =
                se.awayScore;
            } else {
              if (
                !sportEvents[se.sportId][se.tournamentId][se.eventId]
                  .cricketHomeScore
              ) {
                sportEvents[se.sportId][se.tournamentId][se.eventId].homeScore =
                  se.homeScore;
              }
              if (
                !sportEvents[se.sportId][se.tournamentId][se.eventId]
                  .cricketAwayScore
              ) {
                sportEvents[se.sportId][se.tournamentId][se.eventId].awayScore =
                  se.awayScore;
              }
            }
            sportEvents[se.sportId][se.tournamentId][se.eventId].matchStatus =
              se.matchStatus;
            sportEvents[se.sportId][se.tournamentId][se.eventId].eventTime =
              se.eventTime;
            for (const m of se.marketOddsDataSet) {
              const foundIndex = sportEvents[se.sportId][se.tournamentId][
                se.eventId
              ].marketOddsDataSet.findIndex(
                (market) => market.marketId === m.marketId
              );
              if (foundIndex !== -1) {
                sportEvents[se.sportId][se.tournamentId][
                  se.eventId
                ].marketOddsDataSet[foundIndex] = m;
              } else {
                sportEvents[se.sportId][se.tournamentId][
                  se.eventId
                ].marketOddsDataSet.push(m);
              }
            }
          } else {
            sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          }
        } else {
          sportEvents[se.sportId][se.tournamentId] = {};
          sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
        }
      } else {
        sportEvents[se.sportId] = {};
        sportEvents[se.sportId][se.tournamentId] = {};
        sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
      }
      // update exchange sport events
      const exchSportEvents = { ...state.exchangeSportEvents };
      if (se.sportId === 'sr:sport:21' && se.sportId in exchSportEvents) {
        if (se.tournamentId in exchSportEvents[se.sportId]) {
          if (se.eventId in exchSportEvents[se.sportId][se.tournamentId]) {
            for (const m of se.marketOddsDataSet) {
              const foundIndex = exchSportEvents[se.sportId][se.tournamentId][
                se.eventId
              ].marketOddsDataSet.findIndex(
                (market) => market.marketId === m.marketId
              );
              if (foundIndex !== -1) {
                exchSportEvents[se.sportId][se.tournamentId][
                  se.eventId
                ].marketOddsDataSet[foundIndex] = m;
              } else {
                exchSportEvents[se.sportId][se.tournamentId][
                  se.eventId
                ].marketOddsDataSet.push(m);
              }
            }
          }
        }
      }

      // add sport to active sports if not already added
      const sportId = se.sportId;
      let currentActiveSports = [...state.activeSports];
      if (currentActiveSports && currentActiveSports.length > 0) {
        const foundIndex = currentActiveSports.findIndex(
          (s) => s.id === sportId
        );
        if (foundIndex === -1) {
          if (SPORTS_MAP[sportId]) {
            currentActiveSports.push(SPORTS_MAP[sportId]);
          }
        }
      } else {
        if (SPORTS_MAP[sportId]) {
          currentActiveSports = [];
          currentActiveSports.push(SPORTS_MAP[sportId]);
        }
      }

      // add tournament to active tournaments if not already added
      let currentActiveTournaments = [...state.activeTournaments];
      const currentFilter = state.filter;
      const eventTime = moment(se.scheduledTime).utc();
      switch (currentFilter) {
        case 'all': {
          currentActiveTournaments = updateCurrentActiveTournaments(
            currentActiveTournaments,
            se
          );
          break;
        }
        case 'live': {
          if (se.live) {
            currentActiveTournaments = updateCurrentActiveTournaments(
              currentActiveTournaments,
              se
            );
          }
          break;
        }
        case 'today': {
          const today = moment().utc().startOf('day');
          const tomorrow = today.clone().add(1, 'days');
          if (
            eventTime.isSameOrAfter(today, 'day') &&
            eventTime.isBefore(tomorrow, 'day')
          ) {
            currentActiveTournaments = updateCurrentActiveTournaments(
              currentActiveTournaments,
              se
            );
          }
          break;
        }
        case 'tomorrow': {
          const today = moment().utc().startOf('day');
          const tomorrow = today.clone().add(1, 'days');
          const dayAfter = today.clone().add(2, 'days');
          if (
            eventTime.isSameOrAfter(tomorrow, 'day') &&
            eventTime.isBefore(dayAfter, 'day')
          ) {
            currentActiveTournaments = updateCurrentActiveTournaments(
              currentActiveTournaments,
              se
            );
          }
          break;
        }
        case 'upcoming': {
          const today = moment().utc().startOf('day');
          if (eventTime.isAfter(today, 'day')) {
            currentActiveTournaments = updateCurrentActiveTournaments(
              currentActiveTournaments,
              se
            );
          }
          break;
        }
      }

      return {
        ...state,
        activeSports: currentActiveSports,
        activeTournaments: currentActiveTournaments,
        sportEvents: sportEvents,
        exchangeSportEvents: exchSportEvents,
      };
    }

    case EXCHANGE_ODDS_CHANGE: {
      const se: ExchangeSportEventWSMsgData = action.payload;
      const exchSportEvents = { ...state.exchangeSportEvents };

      if (se.sportId in exchSportEvents) {
        if (se.tournamentId in exchSportEvents[se.sportId]) {
          if (se.eventId in exchSportEvents[se.sportId][se.tournamentId]) {
            const markets = Object.keys(se.exchangeMarketHashMap);
            for (const m of markets) {
              exchSportEvents[se.sportId][se.tournamentId][
                se.eventId
              ].exchangeMarketHashMap[m] = se.exchangeMarketHashMap[m];
            }
          } else {
            // exchSportEvents[se.sportId][se.tournamentId][se.eventId] = se;
          }
        } else {
          // exchSportEvents[se.sportId][se.tournamentId] = {};
          // exchSportEvents[se.sportId][se.tournamentId][se.eventId] = se;
        }
      } else {
        // sportEvents[se.sportId] = {};
        // sportEvents[se.sportId][se.tournamentId] = {};
        // sportEvents[se.sportId][se.tournamentId][se.eventId] = se;
      }

      return {
        ...state,
        exchangeSportEvents: exchSportEvents,
      };
    }

    case BET_STOP: {
      const betStopPayload: BetStopPayload = action.payload;
      const { sportId, eventId, tournamentId, marketGroups } = betStopPayload;
      const sportEvents = { ...state.sportEvents };
      if (marketGroups.includes('all')) {
        if (sportId in sportEvents) {
          if (tournamentId in sportEvents[sportId]) {
            if (eventId in sportEvents[sportId][tournamentId]) {
              sportEvents[sportId][tournamentId][
                eventId
              ].marketOddsDataSet = sportEvents[sportId][tournamentId][
                eventId
              ].marketOddsDataSet.map((m) => ({ ...m, isActive: 'Suspended' }));
            }
          }
        }
      }
      return {
        ...state,
        sportEvents: sportEvents,
      };
    }

    case FILTER_CHANGE: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case SPORT_CHANGE: {
      return {
        ...state,
        selectedSport: action.payload,
      };
    }

    case TOURNAMENT_CHANGE: {
      return {
        ...state,
        selectedTournament: action.payload,
      };
    }

    case EVENT_CHANGE: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }

    case PRODUCER_STATUS_CHANGE: {
      let liveProducerDown = false;
      const p: ProducerInfo = action.payload;
      if (p.producerName === 'LO' && !p.isUp) {
        liveProducerDown = true;
      }

      return {
        ...state,
        isLiveProducerDown: liveProducerDown,
        producerInfo: action.payload,
      };
    }

    case UPDATE_LIVE_PRODUCER_STATUS: {
      return {
        ...state,
        isLiveProducerDown: action.payload,
      };
    }

    case FETCH_ACTIVE_BETS: {
      return {
        ...state,
        fetchActiveBets: action.payload,
      };
    }

    case 'FETCH_BOOKMAKER_PERCENTAGE_SUCCESS': {
      const bmEventData = action.payload;
      const bookmakerPercentagesData = { ...state.bookmakerPercentages };
      bookmakerPercentagesData[bmEventData.eventId] = bmEventData;
      return {
        ...state,
        bookmakerPercentages: bookmakerPercentagesData,
      };
    }

    default:
      return state;
  }
};

export default sportsReducer;
