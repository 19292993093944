import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonLabel, IonSpinner } from '@ionic/react';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import QRCode from 'react-qr-code';
import './EnableMFA.scss';
import { toast } from 'react-toastify';
import { RootState } from '../../models/RootState';
import {
  loginFailed,
  loginSuccess,
  requestEnd,
  requestStart,
} from '../../store';
import { connect } from 'react-redux';
import API_V2 from '../../svls-api';

type StoreProps = {
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
};

type MFAProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
};

const EnableMFA: React.FC<MFAProps> = (props) => {
  const { errorMsg, loading, loginFailed, requestStart, requestEnd } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [qrString, setQrString] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [otp, setOtp] = useState<string>('');

  let history = useHistory();

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      requestStart();
      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password,
        };
        setUsername(values.username.toLowerCase());
        const response = await API_V2.post(
          '/account/two-factor-auth/',
          loginRequest
        );

        requestEnd();

        setQrString(response.data);
      } catch (err) {
        loginFailed(err?.response?.data?.message);
        console.log(err);
      }
    },
  });

  const validateOtp = async () => {
    try {
      const response = await API_V2.post(
        `/account/two-factor-auth/validate/?username=${username}&otp=${otp}`
      );

      if (response.status === 200) {
        history.push('/login');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      loginFailed(error?.response?.data?.message);
      console.log(error);
    }
  };

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {qrString === '' ? (
        <form
          onSubmit={formik.handleSubmit}
          className="login-form-ctn"
          autoComplete="off"
        >
          <div className="title-2fa">Two Factor Authentication</div>

          <span className="usr-input">
            <IonLabel className="input-label">Email / Username</IonLabel>
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              variant="outlined"
              error={
                formik.touched.username && formik.errors.username ? true : false
              }
              helperText={
                formik.touched.username && formik.errors.username
                  ? formik.errors.username
                  : null
              }
              {...formik.getFieldProps('username')}
            />
          </span>

          <div className="pwd-input">
            <IonLabel className="input-label">Password</IonLabel>
            <FormControl
              className="login-input-field pwd-field"
              variant="outlined"
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
            >
              <OutlinedInput
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                {...formik.getFieldProps('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordClickHandler}
                      onMouseDown={showPasswordClickHandler}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <FormHelperText error id="my-helper-text">
                  {formik.errors.password}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>

          {errorMsg !== '' ? (
            <>
              <span className="login-err-msg">{errorMsg}</span>
              <span className="login-redirection">
                <NavLink to={'/login'}>Redirect to login</NavLink>
              </span>
            </>
          ) : null}

          <div className="login-signup-btn">
            <Button
              className="login-form-btn two-factor"
              color="primary"
              endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
              type="submit"
              variant="contained"
            >
              Get QR
            </Button>
          </div>
        </form>
      ) : (
        <div className="scanner-ctn">
          <div className="scanner">
            <QRCode
              size={128}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
              }}
              value={qrString}
              viewBox={`0 0 256 256`}
            />
          </div>
          <span className="usr-input">
            <IonLabel className="input-label">OTP *</IonLabel>
            <TextField
              className=""
              type="number"
              name="otp"
              variant="outlined"
              required
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
          </span>

          {errorMsg !== '' ? (
            <span className="login-err-msg">{errorMsg}</span>
          ) : null}

          <Button
            className="login-form-btn"
            color="primary"
            type="submit"
            variant="contained"
            endIcon={loading ? <IonSpinner name="lines-small" /> : ''}
            onClick={validateOtp}
          >
            Verify
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    errorMsg: state.auth.loginError,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableMFA);
