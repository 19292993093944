import { ExBetslip } from '../../models/RootState';
import { FETCH_OPEN_BETS_CACHE_SUCCESS } from './exchBetslipActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExBetslip = {
  openBets: [],
  openBetsCache: [],
  totalOrders: 0,
};

const ExchBetslipReducer = (
  state = initialState,
  action: Action
): ExBetslip => {
  switch (action.type) {
    // case FETCH_OPEN_BETS_SUCCESS: {
    //   const openBets = action.payload;
    //   openBets.sort((a, b) => (a.betPlacedTime < b.betPlacedTime ? 1 : -1));
    //   return {
    //     ...state,
    //     openBets: openBets,
    //   };
    // }
    case FETCH_OPEN_BETS_CACHE_SUCCESS: {
      const openBets = action.payload.result;
      const totalOrders = action.payload.totalOrders;
      return {
        ...state,
        openBetsCache: openBets,
        totalOrders: totalOrders,
      };
    }
    default: {
      return state;
    }
  }
};

export default ExchBetslipReducer;
