export const BET_STOP = 'BET_STOP';
export const FETCH_ACTIVE_SPORTS_SUCCESS = 'FETCH_ACTIVE_SPORTS_SUCCESS';
export const FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START =
  'FETCH_ACTIVE_TOURN_BY_SPORT_REQ_START';
export const FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS =
  'FETCH_ACTIVE_TOURN_BY_SPORT_SUCCESS';
export const FETCH_MARKET_ODDS_SUCCESS = 'TOGGLE_DRAWER';
export const FETCH_MARKET_ODDS_FAILED = 'FETCH_MARKET_ODDS_FAILED';
export const FEATCH_TOURN_BY_SPORT_SUCCESS = 'FEATCH_TOURN_BY_SPORT_SUCCESS';
export const FETCH_SPORT_EVENTS_SUCCESS = 'FETCH_SPORT_EVENTS_SUCCESS';
export const MARKET_ODDS_FAILED = 'MARKET_ODDS_FAILED';
export const PRODUCER_STATUS_CHANGE = 'PRODUCER_STATUS_CHANGE';
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_FAILED = 'REQUEST_FAILED';
export const FEATCH_SPORTS_SUCCESS = 'FEATCH_SPORTS_SUCCESS';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const ODDS_CHANGE = 'ODDS_CHANGE';
export const FILTER_CHANGE = 'FILTER_CHANGE';
export const SPORT_CHANGE = 'SPORT_CHANGE';
export const TOURNAMENT_CHANGE = 'TOURNAMENT_CHANGE';
export const EVENT_CHANGE = 'EVENT_CHANGE';
export const UPDATE_LIVE_PRODUCER_STATUS = 'UPDATE_LIVE_PRODUCER_STATUS';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const FETCH_ACTIVE_BETS = 'FETCH_ACTIVE_BETS';
export const FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS =
  'FETCH_EXCHANGE_SPORT_EVENTS_SUCCESS';
export const EXCHANGE_ODDS_CHANGE = 'EXCHANGE_ODDS_CHANGE';
export const FETCH_BOOKMAKER_PERCENTAGE_SUCCESS =
  'FETCH_BOOKMAKER_PERCENTAGE_SUCCESS';
export const FETCH_BOOKMAKER_PERCENTAGE_FAILED =
  'FETCH_BOOKMAKER_PERCENTAGE_FAILED';
