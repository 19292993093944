import { DashboardState } from '../../models/DashboardState';
import { FETCH_MARKET_ANALYSIS_DATA_SUCCESS } from './dashboardActionTypes';


type Action = {
  type: string;
  payload: any;
};

const initialState: DashboardState = {
  marketAnalysisData: [],
};

const dashboardReducer = (
  state = initialState,
  action: Action
): DashboardState => {
  switch (action.type) {
    case FETCH_MARKET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        marketAnalysisData: action.payload
      };

    default:
      return state;
  }
};

export default dashboardReducer;
