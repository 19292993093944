export const IsWLHavePaymentIntigration = {
  wazirexch: true,
  dingo777: true,
  vivaexch: true,
};
export const ShowSelfRagistration: { [key: string]: boolean } = {
  betlaal: true,
  hypexexch: true,
  vivaexch: true,
  ntexch: true,
  speed247exch: true,
  stbexch: true,
  vardaanexch: true,
  allplay99: true,
  classicbook247: true,
  dhanexchange: true,
  betinr: true,
  mazaa365: true,
  faircric: true,
  betex247: true,
  sikander247: true,
  '365fairplay': true,
  shaexch: true,
  jinnyexch: true,
  sultan: true,
  '999betpro': true,
  maruti247: true,
  khelbazi: true,
  richestplay999: true,
  trishul999: true,
  vipexch3: true,
  reya5: true,
  cricanna: true,
  bb777: true,
  mykingexch: true,
  bb247: true,
  theboys7: true,
  owlexch77: true,
  adani99: true,
  mudra999: true,
  kuber333: true,
  shreejiexch: true,
  a7exch: true,
  payquickexch: true,
  krishna365: true,
};

export const HidePasswordIcon: { [key: string]: boolean } = {
  betcom: true,
};

export const EnableBonus: { [key: string]: boolean } = {
  mazaa365: true,
  faircric: true,
  betex247: true,
  krishna365: true,
};
