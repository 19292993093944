import React from 'react';
import './AccessRedirect.scss';
const AccessRedirect: React.FC = () => {
  return (
    <>
      <div className='ion-page access-redirect-page'>
        You are not pemitted to use this site.
      </div>
    </>
  );
};

export default AccessRedirect;
