import { WebSocket } from '../../models/WebSocket';
import { SET_CHANNELS } from './webSocketActionTypes';

type Action = {
  type: string;
  payload: any;
};

const initialState: WebSocket = {
  channels: [],
};

const webSocketReducer = (state = initialState, action: Action): WebSocket => {
  switch (action.type) {
    case SET_CHANNELS: {
      return {
        ...state,
        channels: action.payload,
      };
    }
    default:
      return state;
  }
};

export default webSocketReducer;
