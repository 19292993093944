import {
  RESET_STATE,
  SET_EVENT_TYPE,
  FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  SET_PAGE_NUMBER,
  SET_LOADING,
  RESET_EVENTS,
  UPDATE_SECONDARY_MARKETS,
  UPDATE_FANCY_MARKETS,
  UPDATE_BOOKMAKER_MARKETS,
  DISABLE_EVENT_DATA,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_BINARY_MARKETS,
  UPDATE_PREMIUM_MARKETS,
  UPDATE_PREMIUM_MARKETS_WS,
  FETCH_TOTAL_EVENT_LIST,
  UPDATE_TOPIC_URLS,
  SUSPENDED_MARKETS,
  SET_BETFAIR_WS_CONNECTION,
  SET_SPORTS_RADAR_WS_CONNECTION,
  SET_DREAM_WS_CONNECTION,
  SET_PUSH_NOT_NOTIF_WS_CONNECTION
} from './exchangeSportsActionTypes';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { AxiosResponse } from 'axios';
import API from '../../api';
import { EventDTO } from '../../models/common/EventDTO';
import { PROVIDER_ID } from '../../constants/Branding';
import { EXCH_SPORTS_MAP } from '../../constants/EventTypes';
import SVLS_API from '../../svls-api';

// redux Actions
export const resetExchangeState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};

// EventTypes
export const setEventType = (eventType: SelectedObj) => {
  return {
    type: SET_EVENT_TYPE,
    payload: eventType,
  };
};

export const setPageNumber = (pageNo: number) => {
  return {
    type: SET_PAGE_NUMBER,
    payload: pageNo,
  };
};

const setLoading = (laoding: boolean) => {
  return {
    type: SET_LOADING,
    payload: laoding,
  };
};

// Competitions
// export const fetchCompetitions = (sportId: string) => {
//   return async (dispatch: Function) => {
//     try {
//       if (sportId !== '') {
//         const result = await API.get(
//           `/common-feed/list-competitions/${sportId}`,
//           {
//             params: {
//               providerId: PROVIDER_ID,
//             },
//           }
//         );
//         if (result.data) {
//           const payload = {
//             sportId: sportId,
//             competitions: result.data,
//           };
//           dispatch(fetchCompetitionsSuccess(payload));
//         } else {
//           const payload = {
//             sportId: sportId,
//             competitions: [],
//           };
//           dispatch(fetchCompetitionsSuccess(payload));
//         }
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

const fetchCompetitionsSuccess = (result) => {
  return {
    type: FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
    payload: result,
  };
};

const fetchPremiumMarketsSuccess = (result) => {
  return {
    type: UPDATE_PREMIUM_MARKETS,
    payload: result,
  };
};

export const setCompetition = (competition: SelectedObj) => {
  return {
    type: SET_COMPETITION,
    payload: competition,
  };
};

// // Events
// export const fetchEventsByCompetition = (
//   sportId: string,
//   competitionId: string,
//   events: EventDTO[]
// ) => {
//   return async (dispatch: Function) => {
//     try {
//       if (sportId !== '' && competitionId !== '') {
//         dispatch(setLoading(true));
//         let result = null;
//         if (sessionStorage.getItem('jwt_token')) {
//           result = await API.get(
//             `/common-feed/list-events/${sportId}/${competitionId}`,
//             {
//               headers: {
//                 Authorization: sessionStorage.getItem('jwt_token'),
//               },

//               params: {
//                 providerId: PROVIDER_ID,
//               },
//             }
//           );
//         } else {
//           result = await API.get(
//             `/common-feed/list-events/${sportId}/${competitionId}`,
//             {
//               params: {
//                 providerId: PROVIDER_ID,
//               },
//             }
//           );
//         }

//         let newList = [];
//         if (result && result.data.length > 0) {
//           for (let eventData of result.data) {
//             try {
//               if (eventData.eventId) {
//                 newList.push(eventData.eventId);
//                 const eData: EventDTO = {
//                   // inPlay: eventData.inPlay,
//                   openDate: eventData.openDate,
//                   sportId: sportId,
//                   competitionId: eventData.competitionId,
//                   competitionName: eventData.competitionName
//                     ? eventData.competitionName
//                     : 'Other',
//                   eventId: eventData.eventId,
//                   eventName: eventData.eventName,
//                   marketId: eventData.marketId,
//                   providerName: eventData.providerName,
//                   status: eventData.status,
//                   enableFancy: eventData.market
//                     ? eventData.market.enableFancy
//                     : false,
//                   enableMatchOdds: eventData.market
//                     ? eventData.market.enableMatchOdds
//                     : false,
//                   enableBookmaker: eventData.market
//                     ? eventData?.market?.enableBookmaker
//                     : false,
//                   enablePremium: eventData.market
//                     ? eventData?.market?.enablePremium
//                     : false,
//                 };
//                 const payload = {
//                   eventData: eData,
//                   sportId: sportId,
//                   competitionId: eData.competitionId,
//                   matchOddsData: eventData.market
//                     ? eventData.market.matchOdds.find(
//                         (mo) =>
//                           mo.marketName === 'Match Odds' ||
//                           mo.marketName.toLowerCase() === 'moneyline'
//                       )
//                     : null,
//                 };

//                 dispatch(fetchEventByCompetitionSuccess(payload));
//                 if (eData.sportId === '1' || eData.sportId === '4') {
//                   for (let mo of eventData.market.matchOdds) {
//                     if (
//                       mo.marketName !== 'Match Odds' &&
//                       mo.marketName.toLowerCase() !== 'moneyline'
//                     ) {
//                       const secMOPayload = {
//                         eventId: eData.eventId,
//                         marketId: mo.marketId,
//                         matchOddsData: mo,
//                       };
//                       dispatch(updateSecondaryMatchOdds(secMOPayload));
//                     }
//                   }
//                 }
//                 if (eData.sportId === '4') {
//                   const secMarketsPayload = {
//                     eventId: eData.eventId,
//                     bookmakerOddsData: eventData.market
//                       ? eventData.market.bookmakers
//                       : null,
//                     enableBookmaker: eventData.market
//                       ? eventData.market.enableBookmaker
//                       : false,
//                     sessionOddsData: eventData.market
//                       ? eventData.market.fancyMarkets
//                       : null,
//                     enableFancy: eventData.market
//                       ? eventData.market.enableFancy
//                       : false,
//                   };
//                   dispatch(updateSecondaryMarkets(secMarketsPayload));
//                 }
//               }
//             } catch (err) {
//               console.log(err);
//             }
//           }
//           if (events && events.length > 0) {
//             for (let ie of events) {
//               if (!newList.includes(ie.eventId)) {
//                 const payload = {
//                   sportId: ie.sportId,
//                   competitionId: ie.competitionId,
//                   eventId: ie.eventId,
//                   disableEvent: false,
//                 };
//                 dispatch(disableEventData(payload));
//               }
//             }
//           }
//         } else {
//           for (let ie of events) {
//             const payload = {
//               sportId: ie.sportId,
//               competitionId: ie.competitionId,
//               eventId: ie.eventId,
//               disableEvent: true,
//             };
//             dispatch(disableEventData(payload));
//           }
//         }
//         dispatch(setLoading(false));
//       }
//     } catch (err) {
//       console.log(err);
//       dispatch(setLoading(false));
//     }
//   };
// };

export const updateTopicUrls = (payload) => {
  return {
    type: UPDATE_TOPIC_URLS,
    payload: payload,
  };
};

const updateTopicUrlsInStore = (dispatch: Function, eventData: any) => {
  // Dispatch topic urls
  const topicUrlPayload = {
    matchOddsBaseUrl: eventData?.markets?.matchOddsBaseUrl,
    matchOddsTopic: eventData?.markets?.matchOddsTopic,
    bookMakerBaseUrl: eventData?.markets?.bookMakerBaseUrl,
    bookMakerTopic: eventData?.markets?.bookMakerTopic,
    fancyBaseUrl: eventData?.markets?.fancyBaseUrl,
    fancyTopic: eventData?.markets?.fancyTopic,
    premiumBaseUrl: eventData?.markets?.premiumBaseUrl,
    premiumTopic: eventData?.markets?.premiumTopic,
  }
  console.log("urls are ", topicUrlPayload)
  dispatch(updateTopicUrls(topicUrlPayload));
}

export const fetchEventsBySport = (sportId: string, events: EventDTO[]) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '') {
        dispatch(setLoading(true));
        let result = null;
        if (sessionStorage.getItem('jwt_token')) {
          result = await SVLS_API.get(`/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`, {
            headers: {
              Authorization: sessionStorage.getItem('jwt_token'),
            },
            params: {
              providerId: PROVIDER_ID,
            },
          });
        } else {
          result = await API.get(`/catalog/v2/sports-feed/sports/${sportId}/competitions/*/events`, {
            params: {
              providerId: PROVIDER_ID,
            },
          });
        }

        let newList = [];
        if (result && result.data.length > 0) {
          updateTopicUrlsInStore(dispatch, result.data[0]);
          for (let eventData of result.data) {
            try {
              if (eventData.eventId) {
                newList.push(eventData.eventId);
                const eData: EventDTO = {
                  // inPlay: eventData.inPlay,
                  enabled: eventData.enabled,
                  openDate: eventData.openDate,
                  sportId: sportId,
                  markets: eventData.markets,
                  competitionId: eventData.competitionId,
                  competitionName: eventData.competitionName
                    ? eventData.competitionName
                    : 'Other',
                  eventId: eventData.eventId,
                  eventName: eventData.eventName,
                  marketId: eventData.marketId,
                  providerName: eventData.providerName,
                  status: eventData.status,
                  // enableFancy: eventData.market
                  //   ? eventData.market.enableFancy
                  //   : false,
                  // enableMatchOdds: eventData.market
                  //   ? eventData.market.enableMatchOdds
                  //   : false,
                  // enableBookmaker: eventData.market
                  //   ? eventData?.market?.enableBookmaker
                  //   : false,
                  // enablePremium: eventData.market
                  //   ? eventData?.market?.enablePremium
                  //   : false,
                };
                const payload = {
                  eventData: eData,
                  sportId: sportId,
                  competitionId: eData.competitionId,
                  matchOddsData:
                    eventData.markets && eventData.markets.matchOdds
                      ? eventData.markets.matchOdds.find(
                          (mo) =>
                            mo.marketName === 'Match Odds' ||
                            mo.marketName.toLowerCase() === 'moneyline'
                        )
                      : null,
                  raceMarkets:
                    eventData.markets && eventData.markets.matchOdds
                      ? eventData.markets.matchOdds
                      : [],
                };
                dispatch(fetchEventByCompetitionSuccess(payload));
                if (eData.sportId === '1') {
                  for (let mo of eventData.markets.matchOdds) {
                    if (
                      mo.marketName !== 'Match Odds' &&
                      mo.marketName.toLowerCase() !== 'moneyline'
                    ) {
                      const secMOPayload = {
                        eventId: eData.eventId,
                        marketId: mo.marketId,
                        matchOddsData: mo,
                      };
                      dispatch(updateSecondaryMatchOdds(secMOPayload));
                    }
                  }
                }

                if (eData.sportId === '4') {
                  const secMarketsPayload = {
                    eventId: eData.eventId,
                    bookmakerOddsData: eventData.markets
                      ? eventData.markets.bookmakers
                      : null,
                    enableBookmaker: eventData.markets
                      ? eventData.markets.enableBookmaker
                      : false,
                    sessionOddsData: eventData.markets
                      ? eventData.markets.fancyMarkets
                      : null,
                    enableFancy: eventData.markets
                      ? eventData.markets.enableFancy
                      : false,
                  };
                  dispatch(updateSecondaryMarkets(secMarketsPayload));
                }
              }
            } catch (err) {
              console.log(err);
            }
          }
          if (events && events.length > 0) {
            for (let ie of events) {
              if (!newList.includes(ie.eventId)) {
                const payload = {
                  sportId: ie.sportId,
                  competitionId: ie.competitionId,
                  eventId: ie.eventId,
                  disableEvent: false,
                };
                dispatch(disableEventData(payload));
              }
            }
          }
        } else {
          if (events) {
            for (let ie of events) {
              const payload = {
                sportId: ie.sportId,
                competitionId: ie.competitionId,
                eventId: ie.eventId,
                disableEvent: false,
              };
              dispatch(disableEventData(payload));
            }
          }
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
    }
  };
};

// export const fetchInplayEvents = () => {
//   return async (dispatch: Function) => {
//     try {
//       dispatch(setLoading(true));
//       let result = null;
//       if (sessionStorage.getItem('jwt_token')) {
//         result = await API.get(`/common-feed/list-inplay-events`, {
//           headers: {
//             Authorization: sessionStorage.getItem('jwt_token'),
//           },
//           params: {
//             providerId: PROVIDER_ID,
//           },
//         });
//       } else {
//         result = await API.get(`/common-feed/list-inplay-events`, {
//           params: {
//             providerId: PROVIDER_ID,
//           },
//         });
//       }

//       let newList = [];
//       if (result && result.data.length > 0) {
//         for (let eventData of result.data) {
//           try {
//             if (eventData.eventId) {
//               newList.push(eventData.eventId);
//               const eData: EventDTO = {
//                 // inPlay: eventData.inPlay,
//                 openDate: eventData.openDate,
//                 sportId: EXCH_SPORTS_MAP[eventData.sportId],
//                 competitionId: eventData.competitionId,
//                 competitionName: eventData.competitionName
//                   ? eventData.competitionName
//                   : 'Other',
//                 eventId: eventData.eventId,
//                 eventName: eventData.eventName,
//                 marketId: eventData.marketId,
//                 providerName: eventData.providerName,
//                 // enableFancy: eventData.market
//                 //   ? eventData?.market?.enableFancy
//                 //   : false,
//                 // enableMatchOdds: eventData.market
//                 //   ? eventData?.market?.enableMatchOdds
//                 //   : false,
//                 // enableBookmaker: eventData.market
//                 //   ? eventData?.market?.enableBookmaker
//                 //   : false,
//                 // enablePremium: eventData.market
//                 //   ? eventData?.market?.enablePremium
//                 //   : false,
//               };
//               const payload = {
//                 eventData: eData,
//                 sportId: eData.sportId,
//                 competitionId: eData.competitionId,
//                 matchOddsData:
//                   eventData.market && eventData.market.matchOdds
//                     ? eventData.market.matchOdds.find(
//                         (mo) =>
//                           mo.marketName === 'Match Odds' ||
//                           mo.marketName.toLowerCase() === 'moneyline'
//                       )
//                     : null,
//               };
//               dispatch(fetchEventByCompetitionSuccess(payload));
//               // if (eData.sportId === '1' || eData.sportId === '4') {
//               //   for (let mo of eventData.market.matchOdds) {
//               //     if (
//               //       mo.marketName !== 'Match Odds' &&
//               //       mo.marketName.toLowerCase() !== 'moneyline'
//               //     ) {
//               //       const secMOPayload = {
//               //         eventId: eventData.eventId,
//               //         marketId: mo.marketId,
//               //         matchOddsData: mo,
//               //       };
//               //       dispatch(updateSecondaryMatchOdds(secMOPayload));
//               //     }
//               //   }
//               // }
//               // if (eData.sportId === '4') {
//               //   const secMarketsPayload = {
//               //     eventId: eData.eventId,
//               //     bookmakerOddsData:
//               //       eventData.market && eventData.market.bookmakers
//               //         ? eventData.market.bookmakers
//               //         : null,
//               //     enableBookmaker: eventData.market
//               //       ? eventData.market.enableBookmaker
//               //       : false,
//               //     sessionOddsData:
//               //       eventData.market && eventData.market.fancyMarkets
//               //         ? eventData.market.fancyMarkets
//               //         : null,
//               //     enableFancy: eventData.market
//               //       ? eventData.market.enableFancy
//               //       : false,
//               //     enableMatchOdds: eventData?.market
//               //       ? eventData?.market?.enableMatchOdds
//               //       : false,
//               //     enablePremium: eventData?.market
//               //       ? eventData?.market?.enablePremium
//               //       : false,
//               //   };
//               //   dispatch(updateSecondaryMarkets(secMarketsPayload));
//               // }
//             }
//           } catch (err) {
//             console.log(err);
//           }
//         }
//       }
//       dispatch(setLoading(false));
//     } catch (err) {
//       console.log(err);
//       dispatch(setLoading(false));
//     }
//   };
// };

export const updateOddsfromWS = (payload) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: payload,
  };
};

export const disableEventData = (payload) => {
  return {
    type: DISABLE_EVENT_DATA,
    payload: payload,
  };
};

export const updatePremiumMarkes = (payload) => {
  return {
    type: UPDATE_PREMIUM_MARKETS_WS,
    payload: payload,
  };
};

const fetchEventByCompetitionSuccess = (result) => {
  return {
    type: FETCH_EVENT_BY_COMPETITION_SUCCESS,
    payload: result,
  };
};

export const clearAllEvents = () => {
  return {
    type: RESET_EVENTS,
    payload: {},
  };
};

export const setExchEvent = (event: SelectedObj) => {
  return {
    type: SET_EXCH_EVENT,
    payload: event,
  };
};

export const fetchEvent = (
  sportId: string,
  competitionId: string,
  eventId: string,
  marketTime?: string
) => {
  return async (dispatch: Function) => {
    try {
      if (sportId !== '' && eventId !== '') {
        let response = null;
        var eventDetails = [{
          providerId: PROVIDER_ID,
          sportId: sportId,
          competitionId: '*',
          eventId: eventId,
          marketTime: marketTime,
        }];
        if (sessionStorage.getItem('jwt_token')) {
          response = await SVLS_API.post(
            `/catalog/v2/sports-feed/sports/markets`,
            {
              providerId: PROVIDER_ID,
              marketsCriteria: 'ALL',
              eventDetails: eventDetails,
            },{
              headers: {
                Authorization: sessionStorage.getItem('jwt_token'),
              },
            },
          );
        } else {
          response = await SVLS_API.post(
            `/catalog/v2/sports-feed/sports/markets`,
            {
              providerId: PROVIDER_ID,
              marketsCriteria: 'ALL',
              eventDetails: eventDetails,
            }
          );
        }

        const eventData = response.data[0];
        if (eventData.eventId) {
          updateTopicUrlsInStore(dispatch, eventData);
          const eData: EventDTO = {
            enabled: eventData.enabled,
            openDate: eventData.openDate,
            markets: eventData.markets,
            sportId: eventData.sportId,
            competitionId: eventData.competitionId,
            competitionName: eventData.competitionName
              ? eventData.competitionName
              : 'Other',
            eventId: eventData.eventId,
            eventName: eventData.eventName,
            marketId: eventData.marketId,
            providerName: eventData.providerName,
            status: eventData.status,
          };

          const payload = {
            eventData: eData,
            sportId: eData.sportId,
            competitionId: eData.competitionId,
            matchOddsData:
              eventData.markets && eventData.markets.matchOdds
                ? eventData.markets.matchOdds.find(
                    (mo) =>
                      mo.marketName === 'Match Odds' ||
                      mo.marketName.toLowerCase() === 'moneyline'
                  )
                : null,
            onRefresh: true,
          };
          
          dispatch(fetchEventByCompetitionSuccess(payload));
          // if (eData.sportId === '1') {
          //|| eData.sportId === '4'
          for (let mo of eventData.markets.matchOdds) {
            if (eData.sportId === '4') {
              if (
                mo.marketName !== 'Match Odds' &&
                mo.marketName.toLowerCase() !== 'moneyline' &&
                mo.marketId !== '1.196548297' &&
                mo.marketId !== '1.196548301'
              ) {
                const secMOPayload = {
                  eventId: eventData.eventId,
                  marketId: mo.marketId,
                  matchOddsData: mo,
                };

                dispatch(updateSecondaryMatchOdds(secMOPayload));
              }
            } else {
              if (
                mo.marketName !== 'Match Odds' &&
                mo.marketName.toLowerCase() !== 'moneyline'
              ) {
                const secMOPayload = {
                  eventId: eventData.eventId,
                  marketId: mo.marketId,
                  matchOddsData: mo,
                };

                dispatch(updateSecondaryMatchOdds(secMOPayload));
              }
            }
          }
          // }

          if (eData.sportId === '4') {
            const secMarketsPayload = {
              eventId: eventData.eventId,
              bookmakerOddsData:
                eventData.markets && eventData.markets.bookmakers
                  ? eventData.markets.bookmakers
                  : null,
              enableBookmaker: eventData.markets
                ? eventData.markets.enableBookmaker
                : false,
              sessionOddsData:
                eventData.markets && eventData.markets.fancyMarkets
                  ? eventData.markets.fancyMarkets
                  : null,
              enableFancy: eventData.markets
                ? eventData.markets.enableFancy
                : false,
              fancySuspended : eventData.markets
                ? eventData.markets.fancySuspended
                :false,
            };
            dispatch(updateSecondaryMarkets(secMarketsPayload));
          }
        } else {
          dispatch(clearAllEvents());
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const updateSecondaryMarkets = (payload) => {
  return {
    type: UPDATE_SECONDARY_MARKETS,
    payload: payload,
  };
};
export const updateFancyMarkets = (payload) => {
  return {
    type: UPDATE_FANCY_MARKETS,
    payload: payload,
  };
};
export const updateBookMakerMarkets = (payload) => {
  // console.log("payload",payload)
  return {
    type: UPDATE_BOOKMAKER_MARKETS,
    payload: payload,
  };
};

export const updateSecondaryMatchOdds = (payload) => {
  return {
    type: UPDATE_SECONDARY_MATCH_ODDS,
    payload: payload,
  };
};

export const updateBinaryMarkets = (payload) => {
  return {
    type: UPDATE_BINARY_MARKETS,
    payload: payload,
  };
};

export const updateSuspendedMarkets = (payload) => {
  return {
    type: SUSPENDED_MARKETS,
    payload: payload,
  };
};

export const updateEventScorecard = (payload) => {
  // console.log(payload);
  return {
    type: UPDATE_SCORECARD,
    payload: payload,
  };
};
// export const fetchPremiummarketsByEventId = (eventId: string) => {
//   return async (dispatch: Function) => {
//     try {
//       if (eventId !== '') {
//         const response: AxiosResponse<any> = await API.get(
//           `/common-feed/list-premium-markets/${eventId}`
//         );
//         const eventData = response.data;
//         if (eventData.eventId) {
//           const payload = {
//             eventId: eventId,
//             eventData: eventData,
//           };
//           dispatch(fetchPremiumMarketsSuccess(payload));
//         }
//       }
//     } catch (ex) {
//       console.log(ex);
//     }
//   };
// };
const fetchCountCategoryEventsSuccess = (result) => {
  return {
    type: FETCH_TOTAL_EVENT_LIST,
    payload: result,
  };
};

// export const fetchCountCategoryEvents = () => {
//   return async (dispatch: Function) => {
//     try {
//       let response = null;
//       if (sessionStorage.getItem('jwt_token')) {
//         response = await API.get(`/common-feed/event-category-count`, {
//           headers: {
//             Authorization: sessionStorage.getItem('jwt_token'),
//           },
//         });
//       } else {
//         response = await API.get(`/common-feed/event-category-count`);
//       }
//       dispatch(fetchCountCategoryEventsSuccess(response.data));
//     } catch (ex) {
//       console.log(ex);
//     }
//   };
// };

export const setBetFairWSConnection = (betFairWSConnected: boolean) => {
  return {
    type: SET_BETFAIR_WS_CONNECTION,
    payload: betFairWSConnected,
  };
};

export const setSportsRadarWSConnection = (sportsRadarWSConnected: boolean) => {
  return {
    type: SET_SPORTS_RADAR_WS_CONNECTION,
    payload: sportsRadarWSConnected,
  };
};

export const setDreamWSConnection = (dreamWSConnected: boolean) => {
  return {
    type: SET_DREAM_WS_CONNECTION,
    payload: dreamWSConnected,
  };
};

export const setPushNotNotifWSConnection = (pushNotNofitConnected: boolean) => {
  return {
    type: SET_PUSH_NOT_NOTIF_WS_CONNECTION,
    payload: pushNotNofitConnected,
  };
};